"use client";
import { useCallback, useState } from "react";
import dynamic from "next/dynamic";
import { DEFAULT_PRODUCT_PICTURE } from "@/constants/common";
import cn from "@/libs/cn";
import useClientContext from "@/context/ClientContext";
import { calcDiscountPercent } from "@/utils/price";
import { formatPrice, isZero } from "@/utils/format";
import PublicIcons from "@/components/basic/PublicIcon";
import routesPublic from "@/modules/public/constants/routesPublic";
import { IApiFile } from "@/types/interfaces/primitives";
import LinkServer from "@/components/basic/Link/LinkServer";
import ImageWithBackground from "@/components/basic/ImageWithBackground";

import styles from "./styles.module.scss";

const ButtonProductLike = dynamic(() => import("../ButtonProductLike"), { ssr: false });

interface IProduct {
  id: number;
  name: string;
  price: string;
  related_price: string | null;
  slug: string;
  main_pic: IApiFile | null;
  average_rate: string | null;
  reviews_count: number | string | null;
  favorites_count: number | string | null;
  quantity: number | null;
}

interface ICardProduct extends IProduct {
  small?: boolean;
  hidePrice?: boolean;
  asInline?: boolean;
  priority?: boolean;
  dirty?: boolean;
  highlightOnHover?: boolean;
  showFavoritesCount?: boolean;
  imageLoading?: "eager" | "lazy";
}

const CardProduct = (props: ICardProduct) => {
  const {
    id,
    small,
    asInline,
    priority,
    dirty,
    highlightOnHover = true,
    showFavoritesCount = true,
    quantity,
    imageLoading,
  } = props;
  const { locale, translate, currency } = useClientContext();

  const { name, price, related_price, main_pic, average_rate, slug, reviews_count, favorites_count } = props;

  const discondPercent = calcDiscountPercent(price, related_price);

  const [favoritesCount, setFavoritesCount] = useState(parseInt(`${favorites_count || '0'}`));
  const onToggleLike = useCallback(
    (liked: boolean) => setFavoritesCount(liked ? favoritesCount + 1 : favoritesCount - 1),
    [favoritesCount]
  );

  return (
    <div className={cn(
      styles.CardProduct,
      asInline && styles.asInline,
      dirty && styles.dirty,
      highlightOnHover && styles.highlightOnHover,
      quantity === 0 && styles.outOfStock,
    )}>
      <div className={cn(styles.imageContainer, small && styles.small)}>
        <ImageWithBackground
          src={main_pic ? main_pic?.file : DEFAULT_PRODUCT_PICTURE}
          className={styles.image}
          alt="thumbnail"
          width={256}
          height={256}
          objectFit="cover"
          quality={85}
          priority={priority}
          target={(!main_pic || main_pic?.name !== "default") ? "product" : undefined }
          loading={imageLoading}
        />
      </div>
      <LinkServer locale={locale} className={styles.link} href={routesPublic.product(slug)} title={name} />
      <div className={styles.info}>
        <div className={styles.block1}>
          <div className={styles.final}>
            {currency.symbol}
            {formatPrice(price, true, currency.value)}
          </div>
          {!isZero(related_price) && price !== related_price && (
            <div className={styles.old}>
              {currency.symbol}
              {formatPrice(related_price, true, currency.value)}
            </div>
          )}
          {discondPercent && <div className={styles.discondPercent}>{discondPercent}%</div>}
          <div style={{ flex: 1 }} />
        </div>
        <div className={styles.block2}>
          <div className={styles.rating} title={translate("Rating")}>
            <PublicIcons name="starFill" width={16} height={16} />
            {/* TODO MOCK */}
            <div className={styles.value}>{average_rate || "0"}</div>
          </div>
          <div className={styles.comment} title={translate("Comments")}>
            <PublicIcons name="comment" width={16} height={16} />
            <div className={styles.value}>{reviews_count || "0"}</div>
          </div>
          <div style={{ flex: 1 }} />
          <div className={styles.favorites}>
            <div className={cn(styles.value, !showFavoritesCount && styles.hidden)}>{favoritesCount}</div>
            <ButtonProductLike id={id} className={styles.like} size={20} iconSize={20} onToggle={onToggleLike}/>
          </div>
        </div>
        <div className={styles.title}>{name}</div>
      </div>

    </div>
  );
};

export default CardProduct;
