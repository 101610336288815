import React from "react";
import cn from "@/libs/cn";

import styles from "./styles.module.scss";

interface PageTitleProps {
  children: string;
  className?: string;
}

const PageTitle: React.FC<PageTitleProps> = (props) => (
  <h1 className={cn(styles.PageTitle, props.className)}>{props.children}</h1>
);

export default PageTitle;
