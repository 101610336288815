import { useMemo } from "react";
import useClientContext from "@/context/ClientContext";
import FilterCheckbox, { FilterListData } from "./basic/FilterCheckbox";
import { LABEL_NO_BRAND } from "../../nextSideRequests";

interface FilterBrandsProps {
  checked: any[];
  onChange: (checked: any[]) => void;
  list: { id: number; name: string; doc_count: number }[];
}

const FilterBrands = (props: FilterBrandsProps) => {
  const { checked, onChange, list } = props;
  const { translate } = useClientContext();

  const data = useMemo(
    (): FilterListData[] =>
      list.map((i) => ({
        value: i.id,
        label: i.name === LABEL_NO_BRAND ? translate(LABEL_NO_BRAND) : i.name,
        quantity: i.doc_count,
      })),
    [list, translate]
  );

  const quantity = useMemo(() => data.reduce((pre, item) => pre + item.quantity, 0), [data]);

  return (
    <FilterCheckbox label={translate("Brands")} data={data} checked={checked} quantity={quantity} onChange={onChange} />
  );
};

export default FilterBrands;
