import { useEffect } from "react";
import disableScroll from "@/utils/disableScroll";

const useDisableScroll = (on: boolean) => {
  useEffect(() => {
    if (on) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }

    return () => {
      disableScroll.off();
    };
  }, [on]);
};

export default useDisableScroll;
