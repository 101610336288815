import { useCallback } from "react";
import FilterList from "./FilterList";

export type FilterListData = {
  value: string | number;
  measurable?: any;
  label: string;
  quantity: any;
};

interface FilterCheckbox {
  label: string;
  data: FilterListData[];
  quantity: number;
  checked: any[];
  onChange: (checked: any[]) => void;
  renderItemLabel?: (item: any) => JSX.Element;
  className?: string;
}

const FilterCheckbox = (props: FilterCheckbox) => {
  const { label, data, quantity, checked = [], onChange: onChangeOut = () => {}, renderItemLabel, className, } = props;

  const onChange = useCallback(
    (id: any, ch: boolean) => {
      let newChecked = [...checked];
      if (ch) {
        newChecked.push(id);
      } else {
        newChecked = newChecked.filter((i) => i != id);
      }

      onChangeOut(newChecked);
    },
    [checked, onChangeOut]
  );

  return (
    <FilterList
      data={data}
      checked={checked}
      quantity={quantity}
      label={label}
      onChange={onChange}
      renderItemLabel={renderItemLabel}
      className={className}
    />
  );
};

export default FilterCheckbox;
