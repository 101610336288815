"use client";
import useClientContext from "@/context/ClientContext";
import { TranslateFunc } from "@/hooks/useTranslate";
import { customerOrderGetTextStatus } from "@/modules/seller/components/orders/statuses";
import { IApiOrderStatus } from "@/types/interfaces/orders";
import useDateRangesString, {PERIOD, PERIOD_LABELS} from '@/hooks/useDateRangesString';
import {rangeYearXISOString} from '@/utils/date';

export const SORTBY_PARAM_NAME = "sortby";
export const CATEGORY_PARAM_NAME = "category";
export const TAGS_PARAM_NAME = "tags";
export const COUNTRY_PARAM_NAME = "country";
export const PROVINCE_PARAM_NAME = "province";
export const CITY_PARAM_NAME = "city";

export const useDataSortByProducts = () => {
  const { translate } = useClientContext();

  const data = [
    {
      value: "",
      label: translate("Default"),
    },
    {
      value: "-rating",
      label: translate("Rating"),
    },
    {
      value: "price",
      label: translate("Cheap to Expensive"),
    },
    {
      value: "-price",
      label: translate("Expensive to Cheap"),
    },
  ];

  return data;
};

export const useDataSortByOrders = () => {
  const { translate } = useClientContext();

  const data = [
    {
      value: "",
      label: translate("Default"),
    },
    {
      value: "date",
      label: translate("Created"),
    },
    {
      value: "price",
      label: translate("Cheap to Expensive"),
    },
    {
      value: "-price",
      label: translate("Expensive to Cheap"),
    },
    // {
    //   value: "status",
    //   label: translate("Status"),
    // },
  ];

  return data;
};

export const getCustomerOrderStatuses = (translate: TranslateFunc) =>
  Object.values(IApiOrderStatus).map((status) => ({
    value: status,
    label: customerOrderGetTextStatus(status, translate),
  }));

export const useDataOrderStatuses = () => {
  const { translate } = useClientContext();

  const data = [
    {
      value: "",
      label: translate("Any"),
    },
    ...getCustomerOrderStatuses(translate),
  ];

  return data;
};

export const useDataOrdersPeriodFilters = () => {
  const { translate } = useClientContext();
  const ranges = useDateRangesString();
  const dateRanges = [PERIOD.TODAY, PERIOD.WEEK, PERIOD.CURRENT_MONTH, PERIOD.CURRENT_YEAR];
  const currentYear = new Date(Date.now()).getFullYear();
  const yearsRanges = [ ...Array(4).keys()].map((i) => ({
    label: `${currentYear - i - 2}`,
    value: rangeYearXISOString(currentYear - i - 2, true),
  }))
  return [
    {
      label: translate("All Time"),
      value: "",
    },
    ...dateRanges.map((range: PERIOD) => ({
      label: translate(PERIOD_LABELS[range]),
      value: ranges[range],
    })),
    // ...yearsRanges
  ];
};
