import cn from "@/libs/cn";
import { QUERY_SEARCH_PARAM_NAME } from "@/constants/common";
import PublicIcons from "@/components/basic/PublicIcon";
import { IApiBreadcrumbsList, IApiCategory } from "@/types/interfaces/categories";
import routesPublic, { getVirtualCategoryURL } from "../../constants/routesPublic";
import LinkServer from "@/components/basic/Link/LinkServer";
import { TranslateFunc } from "@/hooks/useTranslate";

import styles from "./styles.module.scss";

interface SubCategories {
  translate: TranslateFunc;
  locale: string;
  category?: IApiCategory | null;
  breadcrumbs?: IApiBreadcrumbsList;
  isPromotionPage?: boolean;
  categories: {
    id: number;
    name: string;
    slug: string;
    level: number;
    has_children: boolean;
    is_virtual: boolean;
    extra_filters: string | null;
  }[];
  q?: string;
  className?: string;
}

const SubCategories = (props: SubCategories) => {
  const { translate, locale, className, category, breadcrumbs, isPromotionPage, categories, q } = props;

  if (!isPromotionPage && (!category || !breadcrumbs)) {
    return <div>{translate("Category not found")}</div>;
  }

  const slugs = breadcrumbs?.map((item) => item.slug);
  const baseUrlParent = slugs?.slice(0, -1).join("/");

  const baseUrl = breadcrumbs?.map((item) => item.slug).join("/");

  return (
    <nav className={cn(styles.SubCategories, className)}>
      <ul className={styles.categories}>
        {category && (
          <li key="parent" className={cn(styles.item, styles.parentItem)} title={category.name}>
            <LinkServer
              locale={locale}
              href={
                category.level === 0
                  ? q
                    ? `${routesPublic.search()}?${QUERY_SEARCH_PARAM_NAME}=${q}`
                    : routesPublic.root()
                  : `${routesPublic.category()}${baseUrlParent}${q ? `?${QUERY_SEARCH_PARAM_NAME}=${q}` : ""}`
              }
              className={styles.itemIcon}
            >
              <PublicIcons name="arrowShortLeft" width={24} height={24} />
              <span>{category.name}</span>
            </LinkServer>
          </li>
        )}
        {categories
          .filter((c) => isPromotionPage || c.level - 1 === category?.level)
          .map((item) => {
            const { is_virtual, extra_filters, slug } = item;
            
            const href = is_virtual
              ? getVirtualCategoryURL([...(slugs || []), slug], extra_filters, [`${baseUrl}`, item.slug])
              : `${routesPublic.category()}${baseUrl}/${item.slug}/${q ? `?${QUERY_SEARCH_PARAM_NAME}=${q}` : ""}`;

            return (
            <li key={item.id} className={cn(styles.item, styles.hovered)} title={item.name}>
              <LinkServer
                locale={locale}
                href={href}
              >
                <span className={styles.name}>{item.name}</span>
                <span className={styles.space} />
                {item.has_children && <PublicIcons name="arrowRight" width={24} height={24} />}
              </LinkServer>
            </li>
          )})}
        <div style={{ marginBottom: 12 }} />
      </ul>
    </nav>
  );
};

export default SubCategories;
