// @ts-nocheck
import React, { SyntheticEvent, useCallback, useId, useRef } from "react";
import cn from "@/libs/cn";
import PublicIcons from "@/components/basic/PublicIcon";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";
import LoaderCircle from "../LoaderCircle";

type IconPosition = "left" | "right";

export interface PropsTextInput extends React.HTMLProps<HTMLInputElement> {
  value?: string;
  label?: string;
  type?: "textarea" | "text" | "number" | string;
  className?: string;
  minHeight?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: Event) => void;
  onBlur?: (e: React.FocusEventHandler<HTMLInputElement>) => void;
  onClean?: () => void;
  replacementCharacters?: { [key: string]: string };
  hideButtonClean?: boolean;
  icon?: JSX.Element;
  iconPosition?: IconPosition;
  placeholder?: string;
  classNameWrap?: string;
  classNameIcon?: string;
  replaceString?: string;
  classNameLabel?: string;
  autoComplete?: string;
  loading?: boolean;
  error?: boolean;
  showCharsCounter?: boolean;
  maxCharacters?: number;
  charsCount?: number;
  charsCounterClassName?: string;
}

const IconPositionClasses: Record<IconPosition, string> = {
  left: "icon-left",
  right: "icon-right",
};

const IconPositionStyles: Record<IconPosition, string> = {
  left: styles.iconLeft,
  right: styles.iconRight,
};

const TextInput = (props: PropsTextInput) => {
  const {
    value,
    label,
    type = "text",
    classNameWrap,
    className,
    classNameIcon,
    classNameLabel,
    minHeight,
    onChange = (e: Event) => {},
    onBlur = (e) => {},
    onKeyPress = () => {},
    onClean: onCleanOut = () => {},
    replacementCharacters = {},
    hideButtonClean = true,
    icon,
    iconPosition = "left",
    replaceString,
    disabled,
    autoComplete,
    loading,
    error = false,
    showCharsCounter,
    charsCounterClassName,
    maxCharacters,
    charsCount = 0,
    ...rest
  } = props;

  const ref = useRef(null);
  const random = useId();

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      const { value } = event.target;

      let raw = `${value}`;
      Object.keys(replacementCharacters).forEach((key) => {
        raw = raw.replaceAll(key, replacementCharacters[key]);
        }
      );

      if (type === "number") {
        event.target.value = raw.replace(/[^\d.]/gi, "");
      } else {
        if (raw !== value) {
          event.target.value = raw;
        }
      }

      onChange(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, JSON.stringify(replacementCharacters), type]
  );

  const onClean = useCallback(() => {
    ref.current.value = "";
    handleChange({ target: { value: "", name: rest.name } });
    onCleanOut();
  }, [handleChange, onCleanOut, rest.name]);

  const handleBlur = useCallback(
    (e: Event) => {
      try {
        onBlur(e);
      } catch (err) {
        console.warn("TextInput error onBlur", err);
      }
    },
    [onBlur]
  );

  const Tag = type === "textarea" ? type : "input";
  const showCleanButton = !disabled && !!value && !hideButtonClean;

  return (
    <>
      {!!label && <div className={cn(stylesForms.basicLabel, showCharsCounter && stylesForms.charsCounterWrapper, classNameLabel)}>
        {label}
        {showCharsCounter &&
          <div className={cn(stylesForms.charsCounter, stylesForms.charsCounterInLabel, charsCounterClassName)}>
            {`${charsCount || 0}/${maxCharacters}`}
          </div>
        }
      </div>}
      <div
        className={cn(
          stylesForms.basicWrapInput,
          !value && stylesForms.basicIsEmpty,
          type === "textarea" && stylesForms.textarea,
          className,
          classNameWrap,
        )}
      >
        {icon && (
          <div
            className={cn(
              IconPositionClasses[iconPosition] && IconPositionStyles[iconPosition],
              styles.icon,
              classNameIcon
            )}
          >
            <div>{icon}</div>
          </div>
        )}

        <Tag
          ref={ref}
          value={value}
          className={cn(
            styles.input,
            icon && styles.withIcon,
            showCleanButton && styles.showCleanButton,
            icon && IconPositionClasses[iconPosition] && IconPositionStyles[iconPosition],
            error && stylesForms.error,
            showCharsCounter && !label && styles.withCharsCount,
            className
          )}
          autoComplete={autoComplete === "off" ? `off-${random}` : autoComplete}
          style={{ minHeight }}
          type={type === "number" ? "text" : type}
          disabled={disabled}
          {...rest}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={onKeyPress}
        />

        {showCleanButton && (
          <div className={styles.clean}>
            <PublicIcons name="close2" width={24} height={24} onClick={onClean} alt="clean" />
          </div>
        )}
        {showCharsCounter && !label &&
          <div className={cn(stylesForms.charsCounter, type === "textarea" && stylesForms.charsCounterTextarea, charsCounterClassName)}>
            {`${charsCount || 0}/${maxCharacters}`}
          </div>
        }
        {loading && <LoaderCircle size={24} stretchToSpace classNameWrap={styles.loader} />}
      </div>
    </>
  );
};

export default TextInput;
