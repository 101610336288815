import React from "react";
import cn from "@/libs/cn";
import LinkServer from "@/components/basic/Link/LinkServer";
import routesPublic from "@/modules/public/constants/routesPublic";
import { IApiBreadcrumbsList } from "@/types/interfaces/categories";
import { IApiStore } from "@/types/interfaces/stores";
import { TranslateFunc } from "@/hooks/useTranslate";
import { IApiProductDetails } from "@/types/interfaces/products";

import styles from "./styles.module.scss";
import routesHome from "../../constants/routesHome";

export interface ISimpleBreadcrumb {
  name: string;
  slug: string;
}

interface Breadcrumbs {
  locale: string;
  translate: TranslateFunc;
  data?: IApiBreadcrumbsList | ISimpleBreadcrumb[];
  isPageCategory?: boolean;
  isPageSearch?: boolean;
  promo?: { id: string; title: string };
  product?: IApiProductDetails;
  isPageShops?: boolean;
  isPageShop?: boolean;
  isPageCart?: boolean;
  staticPage?: {
    href: string;
    title: string;
  };
  isPageHelp?: boolean;
  store?: IApiStore | null;
  className?: string;
}

const Breadcrumbs = ({
  locale,
  translate,
  data = [],
  isPageCategory,
  isPageSearch,
  promo,
  product,
  isPageShops,
  isPageShop,
  isPageCart,
  isPageHelp,
  staticPage,
  store,
  className,
}: Breadcrumbs) => {
  if (isPageHelp) {
    return (
      <ol className={cn(styles.Breadcrumbs, className)}>
        <li>
          <LinkServer locale={locale} href={routesPublic.root()} className={styles.link}>
            {translate("Home")}
          </LinkServer>
        </li>
        <div className={styles.separator}>/</div>
        <li>
          <LinkServer locale={locale} href={routesHome.help()} className={styles.link}>
            {translate("Help")}
          </LinkServer>
        </li>
        {!!data.length && <div className={styles.separator}>/</div>}
        {data.map((item, idx) => {
          const chain = data.slice(0, idx + 1);
          const url = chain.map((categoryItem) => categoryItem.slug).join("/");

          return (
            <li key={url}>
              <LinkServer locale={locale} href={routesHome.helpCategory(url)} className={styles.link}>
                {item.name}
              </LinkServer>
              {idx !== data.length - 1 && <div className={styles.separator}>/</div>}
            </li>
          );
        })}
      </ol>
    );
  }

  if (staticPage) {
    return (
      <ol className={cn(styles.Breadcrumbs, className)}>
        <li>
          <LinkServer locale={locale} href={routesPublic.root()} className={styles.link}>
            {translate("Home")}
          </LinkServer>
        </li>
        <div className={styles.separator}>/</div>
        <li>
          <LinkServer locale={locale} href={staticPage.href} className={styles.link}>
            {staticPage.title}
          </LinkServer>
        </li>
      </ol>
    );
  }

  return (
    <ol className={cn(styles.Breadcrumbs, className)}>
      <li>
        <LinkServer locale={locale} href={routesPublic.root()} className={styles.link}>
          {translate("Home")}
        </LinkServer>
        <div className={styles.separator}>/</div>
      </li>
      {isPageCart && (
        <li>
          <LinkServer locale={locale} href={routesPublic.cart()} className={styles.link}>
            {translate("Cart")}
          </LinkServer>
        </li>
      )}
      {(isPageShops || isPageShop) && (
        <>
          <li>
            <LinkServer locale={locale} href={routesPublic.stores()} className={styles.link}>
              {translate("Shops")}
            </LinkServer>
          </li>
          {store ? (
            <>
              <div className={styles.separator}>/</div>
              <li>
                <LinkServer locale={locale} href={routesPublic.store(store.slug)} className={styles.link}>
                  {store.title}
                </LinkServer>
              </li>
            </>
          ) : null}
        </>
      )}
      {isPageCategory && (
        <li>
          <LinkServer locale={locale} href={routesPublic.category()} className={styles.link}>
            {translate("Catalogue")}
          </LinkServer>
          { data?.length > 0 && <div className={styles.separator}>/</div> }
        </li>
      )}
      {isPageSearch && (
        <li>
          <LinkServer locale={locale} href="#" className={styles.link}>
            {translate("Search")}
          </LinkServer>
        </li>
      )}
      {promo && (
        <li>
          <LinkServer locale={locale} href="#" className={styles.link}>
            {promo.title}
          </LinkServer>
        </li>
      )}
      {product && (
        <li>
          <LinkServer locale={locale} href={routesPublic.category()} className={styles.link}>
            {translate("Catalogue")}
          </LinkServer>
          <div className={styles.separator}>/</div>
        </li>
      )}
      {(isPageCategory || product) &&
        data?.map((item, idx) => {
          const chain = data.slice(0, idx + 1);
          const url = chain.map((categoryItem) => categoryItem.slug).join("/");

          return (
            <li key={url}>
              <LinkServer locale={locale} href={`${routesPublic.category()}${url}`} className={styles.link}>
                {item.name}
              </LinkServer>
              {(idx !== data.length - 1 || product?.brand?.is_custom === false) && <div className={styles.separator}>/</div>}
            </li>
          );
        })}
      {!!product && (() => {
          const noBrand = product.brand === null || product.brand.is_custom === true;
          const last = data[data.length - 1];
          const url = !noBrand && product.brand
            ? `${routesPublic.category()}${data.map((categoryItem) => categoryItem.slug).join("/")}?brand=${
                product.brand.id
              }`
            : //  TODO HARDCODE brand=-1 - filtering a list without a brand
              `${routesPublic.category()}${data.map((categoryItem) => categoryItem.slug).join("/")}?brand=-1`;

          return (
            <>
              <li>
                {noBrand && <div className={styles.separator}>/</div>}
                <LinkServer locale={locale} href={url} className={styles.link}>
                  {last?.name + " " + (!noBrand && product.brand ? product.brand.name : translate("No brand"))}
                </LinkServer>
              </li>
            </>
          );
        })()}
    </ol>
  );
};

export default Breadcrumbs;
