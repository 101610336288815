import {useCallback, useEffect, useMemo, useState} from "react";
import dynamic from "next/dynamic";
import cn from "@/libs/cn";
import useClientContext from "@/context/ClientContext";
import TextInput from "@/controls/TextInput";
import Button from "@/controls/Button";

import styles from "./styles.module.scss";

const RangeDouble = dynamic(() => import("@/controls/RangeDouble"), { ssr: false });

export type FilterPriceRangeValue = { min?: string; max?: string };

interface FilterPriceRange {
  className?: string;
  label: string;
  value: FilterPriceRangeValue;
  min?: string;
  max?: string;
  onChange?: (value: FilterPriceRangeValue) => void;
  onApply?: () => void;
  hideButtonApply?: boolean;
}

const FilterPriceRange = (props: FilterPriceRange) => {
  const {
    className,
    label,
    value,
    min = "",
    max = "",
    onChange: onChangeOut = () => {},
    onApply = () => {},
    hideButtonApply,
  } = props;
  const { translate, currency, isTouchable, isMacOS } = useClientContext();

  const [draftValue, setDraftValue] = useState<FilterPriceRangeValue>(value);
  useEffect(() => {
    setDraftValue(value);
  }, [value]);

  const onChange = useCallback(
    (val: string, isMin: boolean) => {
      const preVal = val.replace(/[^\d]/g, "");
      setDraftValue(oldDraftValue => isMin ? { ...oldDraftValue, min: preVal } : { ...oldDraftValue, max: preVal });
    },
    []
  );

  const onBlur = useCallback(() => {
    let tmin = parseInt(draftValue.min || "0");
    let tmax = parseInt(draftValue.max || "0");

    let rmin: any = parseInt(`${min}`);
    rmin = Number.isNaN(rmin) ? undefined : rmin;

    let rmax: any = parseInt(`${max}`);
    rmax = Number.isNaN(rmax) ? undefined : rmax;

    if (rmin && tmin < rmin) {
      tmin = rmin;
    }

    if (rmax && tmax > rmax) {
      tmax = rmax;
    }

    if (tmin > tmax && draftValue.max) {
      tmin = rmin || 0;
    }

    if (tmax < tmin && draftValue.min) {
      tmax = rmax;
    }

    onChangeOut({ min: `${tmin}`, max: `${tmax}` });
  }, [draftValue, max, min, onChangeOut]);

  const valueMin = useMemo(() => {
    if (typeof draftValue.min !== "undefined") {
      return draftValue.min;
    }

    return min || "";
  }, [draftValue.min, min]);

  const valueMax = useMemo(() => {
    if (typeof draftValue.max !== "undefined") {
      return draftValue.max;
    }

    return max || "";
  }, [draftValue.max, max]);

  const errorMin = useMemo(() => {
    if (typeof value.min === "undefined" || value.min === "") {
      return false;
    }

    if (parseInt(value.min) < parseInt(min || "")) {
      return true;
    }

    if (parseInt(value.min) > parseInt(max || "")) {
      return true;
    }

    return false;
  }, [max, min, value.min]);

  const errorMax = useMemo(() => {
    if (typeof value.max === "undefined" || value.max === "") {
      return false;
    }

    if (parseInt(value.max) < parseInt(min || "")) {
      return true;
    }

    if (parseInt(value.max) > parseInt(max || "")) {
      return true;
    }

    return false;
  }, [value.max, min, max]);

  return (
    <div className={cn(styles.FilterPriceRange, className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.body}>
        <TextInput
          type="number"
          classNameWrap={cn(styles.input, errorMin && styles.error)}
          classNameIcon={cn(styles.icon, !isMacOS && styles.notMacOS)}
          icon={<>{currency.symbol}</>}
          placeholder="1"
          value={errorMin ? min : valueMin}
          onChange={(e) => onChange(e.target.value, true)}
          max={draftValue.max || undefined}
          onBlur={onBlur}
          aria-label={translate("Min price")}
        />
        <div className={styles.delimiter}>—</div>
        <TextInput
          type="number"
          classNameWrap={cn(styles.input, errorMax && styles.error)}
          classNameIcon={cn(styles.icon, !isMacOS && styles.notMacOS)}
          icon={<>{currency.symbol}</>}
          placeholder="1000"
          value={errorMax ? max : valueMax}
          onChange={(e) => onChange(e.target.value, false)}
          min={draftValue.min || undefined}
          onBlur={onBlur}
          aria-label={translate("Max price")}
        />
        {!hideButtonApply && (
          <Button className={styles.button} color="primary" onClick={onApply}>
            {translate("Ok")}
          </Button>
        )}
      </div>
      {/* {(errorMin || errorMax) && (
        <div className={styles.textError}>
          Value must be more than {min} and less than {max}
        </div>
      )} */}
      {
        valueMin != valueMax && (
          <RangeDouble
            className={styles.range}
            min={min}
            max={max}
            valueMin={parseInt(valueMin)}
            valueMax={parseInt(valueMax)}
            onChange={(r) => onChangeOut({ min: `${r.min}`, max: `${r.max}` })}
            isTouchable={isTouchable}
          />
        )
      }
    </div>
  );
};

export default FilterPriceRange;
