import cn from "@/libs/cn";
import ToggleSwitch from "@/controls/ToggleSwitch";

import styles from "./styles.module.scss";

interface FilterToggle {
  className?: string;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const FilterToggle = (props: FilterToggle) => {
  const { className, label, value, onChange: onChangeOut = () => {} } = props;

  return (
    <div className={cn(styles.FilterToggle, className)}>
      <div className={styles.label}>{label}</div>
      <ToggleSwitch value={value} onToggle={onChangeOut} />
    </div>
  );
};

export default FilterToggle;
