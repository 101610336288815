"only server";
import { APP_API, APP_NEXT_API } from "@/constants/common";
import {
  IApiBreadcrumbsList,
  IApiCategoriesList,
  IApiCategory,
  IApiCategoryOfList,
} from "@/types/interfaces/categories";
import { IApiProductDetails, IApiProductNotFoundDetails, IApiProductsList } from "@/types/interfaces/products";
import {
  IApiCustomerProductProperty,
  IApiCustomerProductVariantProperty,
  IApiCustomerPropertyOfList,
  IApiPropertyValueById,
} from "@/types/interfaces/properties";
import { IApiStore } from "@/types/interfaces/stores";
import { IApiPublicProductVariant } from "@/types/interfaces/variants";
import { NextSideError } from "@/types/next-side/requests";
import { uniqueArrayProcessing } from "@/utils/array";
import { nodeFetch } from "@/utils/nodeFetch";
import { mockPromise } from "@/utils/promise";
import { NextPageContext } from "next";
import getProductsFilter from "./components/filters/getProductsFilter";
import getStoresFilter from "./components/filters/getStoresFilter";
import apiPublic, { nextApiPublic } from "./constants/apiPublic";
import {
  IApiAggregatedProductsList,
  IApiBrandAggregated,
  IApiCategoryMapped,
  IApiProductAggregated,
  IApiPropertyCodesAggregated,
  IApiStoreAggregated,
} from "@/types/interfaces/aggregated";
import { IApiBrand } from "@/types/interfaces/primitives";
import { mappingList } from "@/utils/server";
import { sortById, sortByOrdering, sortCategories } from "@/utils/sort";
import { IApiPageMetaData, IApiStaticContent } from "@/types/interfaces/static-content";
import { SitemapItem } from "./pages/api/sitemap/template";
import { IApiSitemaps } from "@/types/interfaces/sitemap";
import { IApiBanner } from "@/types/interfaces/banners";
import { wait } from "@/utils/wait";

const GEO_API_KEY = process.env.GEO_API_KEY;

function getBaseUrl(context: NextPageContext) {
  const { req } = context;

  const protocol = req?.headers.referer?.split("://")[0] ?? "http";

  const host = req?.headers.host;
  const baseUrl = `${protocol}://${host}`;

  return baseUrl;
}

function processPagination(response: Response) {
  const count = response.headers.get("x-pagination-count");
  const limit = response.headers.get("x-pagination-limit");

  let nextPage = response.headers.get("x-pagination-next");
  if (nextPage === "None") {
    nextPage = null;
  }

  let prevPage = response.headers.get("x-pagination-prev");
  if (prevPage === "None") {
    prevPage = null;
  }

  return {
    count: parseInt(`${count}`),
    limit: parseInt(`${limit}`),
    prevPage,
    nextPage,
  };
}

export function makeAbsoluteURL(url: string, nextApi?: boolean) {
  if (nextApi) {
    return url.startsWith("http") ? url : `${process.env.NEXT_PUBLIC_DOMAINE_STAGE}${APP_NEXT_API}${url}`;
  }

  return url.startsWith("http")
    ? url
    : `${process.env.NODE_ENV === "production" ? process.env.NEXT_PUBLIC_DOMAINE_STAGE : ""}${APP_API}${url}`;
}

export interface ResponseAggregatedProductsList {
  products: IApiProductAggregated[];
  min_price: number | null;
  max_price: number | null;
  brands: IApiBrandAggregated[];
  stores: IApiStoreAggregated[];
  property_codes: IApiPropertyCodesAggregated[];
  categories: IApiCategoryMapped[];
  topCategories: IApiCategoryMapped[];
  status: number;
  statusText: string;
  url: string;
  limit: number;
  count: number;
  prevPage: string | null;
  nextPage: string | null;
  nextSideError: NextSideError | null;
}

function removeFilteredCategory(list: any, category: any) {
  return category ? list.filter((i: any) => i.id != category) : list;
}

const SITEMAP_REVALIDATE_INTERVAL = 5 * 60; // 5 mins
const getSitemaps = async function (): Promise<
  | {
      status: 400 | 404 | 500;
      statusText: string;
      url: string;
      data: null;
    }
  | {
      status: 200;
      statusText: string;
      url: string;
      data: IApiSitemaps;
    }
> {
  const url = makeAbsoluteURL(apiPublic.getSitemaps());

  const context: any = {};
  const response = await nodeFetch(context, url, { revalidate: SITEMAP_REVALIDATE_INTERVAL });

  if (response.status >= 400) {
    return {
      // @ts-ignore
      status: response.status,
      statusText: response.statusText,
      url,
      data: null,
    };
  }

  try {
    const data = await response.json();

    return {
      status: 200,
      statusText: response.statusText,
      url,
      data: data,
    };
  } catch (err) {
    return {
      status: 500,
      statusText: 'Server error',
      url,
      data: null,
    };
  }
};

// Not implemented on the backend
type ProductSitemapItem = {
  slug: string;
  last_modified: number | string;
  priority?: number;
  change_frequency?: "always" | "hourly" | "daily" | "weekly" | "monthly" | "yearly" | "never";
};

const sitemapProducts = async function (
  part: number,
  limit: number
): Promise<
  | {
      status: 400 | 404 | 500;
      statusText: string;
      data: null;
    }
  | {
      status: 200;
      statusText: string;
      data: ProductSitemapItem[];
    }
> {
  try {
    // Not implemented on the backend
    // Temporary get the first page of products with offset
    // filters: page_size & limit
    const page_size = 16;
    const filterStr = `page_size=${page_size}&offset=${part * limit}`;
    const url = makeAbsoluteURL(apiPublic.sitemapProductsList(filterStr));

    const context: any = {};
    const response = await nodeFetch(context, url);
    if (response.status !== 200) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    let data = await response.json();
    data =
      data.products?.map((i: ProductSitemapItem) => ({
        slug: i.slug,
        lastModified: i.last_modified,
        priority: i.priority,
        change_frequency: i.change_frequency,
      })) || [];

    return {
      status: 200,
      statusText: "Ok",
      data,
    };
  } catch (err: any) {
    return {
      status: 500,
      statusText: `Server error: ${err.message}`,
      data: null,
    };
  }
};

const getProductsAggregated = async function (
  context: NextPageContext,
  locale: string,
  query: any = {},
  forStatistics?: boolean,
): Promise<ResponseAggregatedProductsList> {
  const { category: categoryId } = query;
  const filterStr = getProductsFilter(query);
  const url = makeAbsoluteURL(apiPublic.aggregatedProductsList(locale, filterStr, forStatistics));
  const response = await nodeFetch(context, url);

  if (response.status === 404) {
    return {
      status: 404,
      nextSideError: null,
      statusText: response.statusText,
      url,
      limit: 0,
      count: 0,
      prevPage: null,
      nextPage: null,
      min_price: null,
      max_price: null,
      products: [],
      brands: [],
      stores: [],
      property_codes: [],
      categories: [],
      topCategories: [],
    };
  }

  let data: IApiAggregatedProductsList | null = {};
  let nextSideError: NextSideError | null = null;
  let virtualCategories: IApiCategory[] = [];

  const aggregated: any = {};

  try {
    data = await response.json();
    aggregated.brandsByIds = await nextSideRequests.getBrandsByIds(context, locale, data?.brands?.map((i) => i.id) || []);
    aggregated.selectedCategories = uniqueArrayProcessing([
      ...(data?.categories || []),
      ...(data?.category_ancestors || []),
    ]);

    const categoriesIds = aggregated.selectedCategories?.map((i: any) => i.id) || [];
    aggregated.topCategories = await nextSideRequests.getTopCategoriesForIds(context, locale, categoriesIds);
    aggregated.categoriesByIds = await nextSideRequests.getCategoriesByIds(context, locale, categoriesIds);

    if (categoryId) {
      virtualCategories = await nextSideRequests.getVirtualCategories(context, locale, categoryId);
    }

  } catch (err) {
    nextSideError = { url, textError: `${err}` };
    return {
      status: 500,
      nextSideError,
      statusText: "",
      url,
      limit: 0,
      count: 0,
      prevPage: null,
      nextPage: null,
      min_price: null,
      max_price: null,
      products: [],
      brands: [],
      stores: [],
      property_codes: [],
      categories: [],
      topCategories: [],
    };
  }

  return {
    status: 200,
    nextSideError,
    statusText: "",
    url,
    products: data?.products || [],
    brands: mappingList(data?.brands, aggregated.brandsByIds),
    stores: data?.stores || [],
    property_codes: data?.property_values || [],
    categories: [
      ...mappingList(removeFilteredCategory(aggregated.selectedCategories, query.category), aggregated.categoriesByIds),
      ...virtualCategories,
    ]
      .sort(sortById)
      .sort(sortByOrdering),
    topCategories: aggregated.topCategories,
    min_price: data?.min_price || null,
    max_price: data?.max_price || null,
    ...processPagination(response),
  };
};

export const LABEL_NO_BRAND = "No brand";
export const NoBrandItem = {
  id: -1,
  name: LABEL_NO_BRAND,
  is_custom: false,
};

const getBrandsByIds = async function (context: NextPageContext, locale: string, ids: number[]): Promise<IApiBrand[]> {
  const url = makeAbsoluteURL(`${apiPublic.brandsByIds(locale, ids)}`);

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data = await response.json();
  return [NoBrandItem, ...data];
};

const getStoresByIds = async function (context: NextPageContext, locale: string, ids: number[]): Promise<IApiStore[]> {
  const url = makeAbsoluteURL(`${apiPublic.storesByIds(locale, ids)}`);

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data = await response.json();

  return data;
};

const getPropertiesValuesByIds = async function (
  context: NextPageContext,
  locale: string,
  ids: number[]
): Promise<IApiPropertyValueById[]> {
  const url = makeAbsoluteURL(`${apiPublic.propertyValuesByIds(locale, ids)}`);

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data = await response.json();

  return data;
};

const getPropertiesByIds = async function (
  context: NextPageContext,
  locale: string,
  ids: number[]
): Promise<IApiCustomerPropertyOfList[]> {
  const url = makeAbsoluteURL(`${apiPublic.propertiesByIds(locale, ids)}`);
  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data = await response.json();

  return data;
};

const getVirtualCategories = async function (context: NextPageContext, locale: string, parent: number): Promise<IApiCategory[]> {
  const url = makeAbsoluteURL(`${nextApiPublic.categoriesWithParent(parent, locale, true)}`, true);

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data: { data: IApiCategory[] } = await response.json();

  return (data.data || []).sort(sortCategories);
};

const getCategoriesByIds = async function (context: NextPageContext, locale: string, ids: number[]): Promise<IApiCategory[]> {
  const url = makeAbsoluteURL(`${nextApiPublic.categoriesByIds(ids, locale)}`, true);

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data: { data: IApiCategory[] } = await response.json();
  return (data.data || []).sort(sortCategories);
};

const getTopCategoriesForIds = async function (context: NextPageContext, locale: string, ids: number[]): Promise<IApiCategory[]> {
  const url = makeAbsoluteURL(`${nextApiPublic.topCategoriesForIds(ids, locale)}`, true);

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data: { data: IApiCategory[] } = await response.json();
  return (data.data || []).sort(sortCategories);
};

const getProduct = async function (context: NextPageContext, slug: string, locale: string) {
  const url = makeAbsoluteURL(`${apiPublic.product(locale, slug)}`);

  const response = await nodeFetch(context, url, { revalidate: 60 });

  try {
    const data = await response.json();
    return data as (IApiProductDetails | IApiProductNotFoundDetails);
  } catch (err) {
    return null;
  }
};

const getPublicFeaturedProducts = async function (context: NextPageContext, locale: string) {
  const url = makeAbsoluteURL(apiPublic.featuredProducts(locale));

  const response = await nodeFetch(context, url);

  try {
    const data = await response.json();
    return data.products || [] as IApiProductsList;
  } catch (err) {
    return [];
  }
};

const getHomeBanners = async function (context: NextPageContext): Promise<IApiBanner[]> {
  const url = makeAbsoluteURL(apiPublic.banners());

  const response = await nodeFetch(context, url, { revalidate: 300 });

  try {
    const data = await response.json();
    return data.sort(sortByOrdering) || [];
  } catch (err) {
    return [];
  }
};

const getProductProperties = async function (context: NextPageContext, locale: string, productId: any) {
  const url = makeAbsoluteURL(`${apiPublic.productProperties(locale, `${productId}`)}`);

  const response = await nodeFetch(context, url);
  if (response.status >= 400) {
    return [];
  }

  const data = await response.json();
  return data as IApiCustomerProductProperty[];
};

const getProperty = async function (
  context: NextPageContext,
  locale: string,
  propertyId: any
): Promise<IApiCustomerPropertyOfList | null> {
  const url = makeAbsoluteURL(`${apiPublic.property(locale, propertyId)}`);

  const response = await nodeFetch(context, url);
  if (response.status >= 400) {
    return await mockPromise<null>(null);
  }

  const data = await response.json();
  return data as IApiCustomerPropertyOfList;
};

const getPropertyValue = async function (
  context: NextPageContext,
  locale: string,
  propertyValueId: any
): Promise<IApiPropertyValueById | null> {
  const url = makeAbsoluteURL(`${apiPublic.propertyValue(locale, propertyValueId)}`);

  const response = await nodeFetch(context, url);
  if (response.status >= 400) {
    return await mockPromise<null>(null);
  }

  const data = await response.json();
  return data as IApiPropertyValueById;
};

const getProductVariants = async function (context: NextPageContext, productSlug: any, locale: string) {
  const url = makeAbsoluteURL(apiPublic.productVariants(locale, `${productSlug}`));

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data = await response.json();
  return data as IApiPublicProductVariant[];
};

const getProductVariantProperties = async function (context: NextPageContext, locale: string, variantId: any) {
  const url = makeAbsoluteURL(`${apiPublic.productVariantProperties(locale, `${variantId}`)}`);

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  const data = await response.json();
  return data as Promise<IApiCustomerProductVariantProperty[]>;
};

const getStoreAllCategories = async function (context: NextPageContext, storeSlug: string) {
  const url = makeAbsoluteURL(`${apiPublic.storeCategories(storeSlug, context.locale)}`);

  const response = await nodeFetch(context, url);
  const data = await response.json();

  return data as IApiCategoriesList;
};

const getStoreTopCategories = async function (context: NextPageContext, storeSlug: string) {
  const url = makeAbsoluteURL(`${apiPublic.storeCategories(storeSlug, context.locale)}&categories_level=0`);

  const response = await nodeFetch(context, url);
  const data = await response.json();

  return data as IApiCategoriesList;
};

const getStoreSubCategories = async function (context: NextPageContext, storeSlug: string, parentCategoryId: any) {
  const url = makeAbsoluteURL(`${apiPublic.storeCategories(storeSlug, context.locale)}&parent=${parentCategoryId}`);

  const response = await nodeFetch(context, url);
  const data = await response.json();

  return data as IApiCategoriesList;
};

const getStores = async function (context: NextPageContext) {
  const { query } = context;
  const { lang } = query as { lang: string };
  const filterStr = getStoresFilter({ ...query, is_trusted: '0' });

  const url = makeAbsoluteURL(`${apiPublic.stores(lang, filterStr)}`);

  const response = await nodeFetch(context, url);

  if (response.status === 404) {
    return {
      status: 404,
      statusText: response.statusText,
      url,
      data: [],
      limit: 0,
      count: 0,
      prevPage: null,
      nextPage: null,
    };
  }

  const data = await response.json();

  return {
    status: 200,
    statusText: "",
    url,
    data,
    ...processPagination(response),
  };
};

const getStore = async function (slug: string, context: NextPageContext) {
  const url = makeAbsoluteURL(`${apiPublic.store(slug)}`);

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return null;
  }

  const data = await response.json();

  return data as IApiStore;
};

const getCategoryBySlug = async function (context: NextPageContext, slug: string) {
  const url = makeAbsoluteURL(apiPublic.categoryBySlug(slug, context.locale));

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return null;
  }

  try {
    const data = await response.json();
    return data as IApiCategory;
  } catch (err) {
    console.log("getCategoryBySlug", err);
  }
  return null;
};

const getBreadcrumbs = async function (context: NextPageContext, id: number) {
  const url = makeAbsoluteURL(apiPublic.breadcrumbs(id, context.locale));

  const response = await nodeFetch(context, url);

  if (response.status >= 400) {
    return [];
  }

  return (await response.json()) as IApiBreadcrumbsList;
};

const getCategoriesUpLevel = async function (context: NextPageContext, level = 2) {
  const url = makeAbsoluteURL(nextApiPublic.categories(context.locale), true);

  const response = await nodeFetch(context, url);
  const data = await response.json();

  return (data.data || [])
    .filter((i: IApiCategoryOfList) => i.level <= level)
    .sort(sortCategories) as IApiCategoriesList;
};

const TOP_CATEGORIES_REVALIDATE_INTERVAL = 5 * 60;
const getTopCategories = async function (context: NextPageContext) {
  const url = makeAbsoluteURL(apiPublic.topCategories(context.locale));

  const response = await nodeFetch(context, url, { revalidate: TOP_CATEGORIES_REVALIDATE_INTERVAL });
  const data = await response.json();

  return (data.categories || []).filter((i: IApiCategoryOfList) => i.parent === null).sort(sortCategories) as IApiCategoriesList;
};

const getCitiesOfCountry = async function (context: NextPageContext, codeCountry: string) {
  const url = makeAbsoluteURL(`${apiPublic.assets()}data/cities/${codeCountry}.json`);

  const response = await nodeFetch(context, url);
  const data = await response.json();

  return data;
};

const getPageMetaData = async function (context: NextPageContext, namePage: string) {
  const url = makeAbsoluteURL(apiPublic.staticPage(`${context.locale}`, namePage));

  const response = await nodeFetch(context, url, {revalidate: 300});
  const data = await response.json();

  return data as IApiPageMetaData | null;
};

const getStaticContent = async function (context: NextPageContext, namePage: string) {
  const url = makeAbsoluteURL(apiPublic.staticPage(`${context.locale}`, namePage));

  const response = await nodeFetch(context, url);
  const data = await response.json();

  if (data.page_content) {
    // Easy XSS obstruction
    data.page_content = `${data.page_content}`.replaceAll("<script", "").replaceAll("<link", "");
  }

  return data as IApiStaticContent | null;
};

const getStaticPages = async function (context: NextPageContext) {
  const url = makeAbsoluteURL(apiPublic.staticPages());

  const response = await nodeFetch(context, url);
  const data = await response.json();

  return data as IApiStaticContent[] | null;
};

const urlGeo = (address: string) =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GEO_API_KEY}`;

const getGeocodeAdress = async function (context: NextPageContext, address: string) {
  const url = urlGeo(encodeURI(address));

  const response = await nodeFetch(context, url);
  const data = await response.json();

  return data;
};

const urlReGeo = (lat: string, lng: string) =>
  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GEO_API_KEY}`;

const getGeocodeLanLng = async function (context: NextPageContext, lat: string, lng: string) {
  const url = urlReGeo(lat, lng);

  const response = await nodeFetch(context, url);
  const data = await response.json();

  return data;
};

const urlAutoComplete = (input: string, language: string) =>
  `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&language=${language}&types=address&key=${GEO_API_KEY}`;

const getGeoAutoComplete = async function (context: NextPageContext, input: string, language: string) {
  const url = urlAutoComplete(input, language);

  const response = await nodeFetch(context, url);
  const data = await response.json();
  console.log("getGeoAutoComplete", data);
  return data;
};

const nextSideRequests = {
  getSitemaps,
  sitemapProducts,
  getProductsAggregated,
  getProduct,
  getPublicFeaturedProducts,
  getBrandsByIds,
  getStoresByIds,
  getPropertiesByIds,
  getPropertiesValuesByIds,
  getVirtualCategories,
  getCategoriesByIds,
  getTopCategoriesForIds,
  getProductProperties,
  getProductVariants,
  getPropertyValue,
  getProperty,
  getProductVariantProperties,
  getStoreAllCategories,
  getStoreTopCategories,
  getStoreSubCategories,
  getStores,
  getStore,
  getCategoryBySlug,
  getBreadcrumbs,
  getCategoriesUpLevel,
  getTopCategories,
  getCitiesOfCountry,
  getStaticContent,
  getPageMetaData,
  getGeocodeAdress,
  getGeocodeLanLng,
  getGeoAutoComplete,
  getStaticPages,
  getHomeBanners,
};

export default nextSideRequests;
