"use client";
import { createPortal } from "react-dom";
import { SyntheticEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import cn from "@/libs/cn";
import LoaderCircle from "@/controls/LoaderCircle";

import styles from "./styles.module.scss";
import { hashObject } from "@/utils/data";
import useViewport from '@/hooks/useViewport';

const ROOT_ELEMENT_SELECTOR = "#backdor";

interface DefaultOptions {
  elem: Element | null;
  align?: "right" | "left";
  verticalAlign?: "bottom" | "top";
  keyUpdate?: any;
  hideWhenResizing?: boolean;
}

const defaultOptions: DefaultOptions = {
  elem: null,
  align: "left",
  verticalAlign: "bottom",
  keyUpdate: undefined,
  hideWhenResizing: true,
};

const useBackdrop = (options: DefaultOptions) => {
  const { elem, align, verticalAlign, keyUpdate, hideWhenResizing } = { ...defaultOptions, ...options };
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(0);

  const onShow = useCallback(() => {
    setShow(true);
  }, []);

  const stopEvent = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    // e.preventDefault();
  }, []);

  const onHide = useCallback(
    (e?: SyntheticEvent) => {
      if (e) {
        stopEvent(e);
      }

      setShow(false);
    },
    [stopEvent]
  );

  useEffect(() => {
    if (!show) {
      return;
    }

    function scroll() {
      if (!show) {
        return;
      }
      setUpdate(Date.now());
    }

    document.addEventListener("scroll", scroll);

    return () => document.removeEventListener("scroll", scroll);
  }, [show]);

  useEffect(() => {
    if (!show || !hideWhenResizing) {
      return;
    }

    function resize() {
      if (!show || !hideWhenResizing) {
        return;
      }
      setShow(false);
    }

    window.addEventListener("resize", resize);
    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", resize);
    }

    return () => {
      window.removeEventListener("resize", resize)
      if (window.visualViewport) {
        window.visualViewport.removeEventListener("resize", resize);
      }
    };
  }, [show, hideWhenResizing]);

  const [rect, setRect] = useState({ width: 0, top: 0, left: 0, right: 0, height: 0 });

  useEffect(() => {
    if (elem && show) {
      const r = elem?.getBoundingClientRect();
      if (r) {
        const { width, top, left, right, height } = r;
        setRect({ width, top, left, right, height });
      }
    }
  }, [elem, show, update]);

  const { width, top, left, right, height } = rect;

  useEffect(() => {
    setTimeout(() => setUpdate(Date.now()), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, hashObject(keyUpdate)]);

  // === Scroll Restoration
  const refContent = useRef<HTMLElement>();
  const elemContent = refContent.current;
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    if (elemContent) {
      elemContent.scrollTop = scrollTop;
    }

    function onScroll(e: any) {
      setScrollTop(e.target.scrollTop);
    }

    elemContent?.addEventListener("scroll", onScroll);

    return () => elemContent?.removeEventListener("scroll", onScroll);
  }, [elemContent, scrollTop, show]);
  // === End Scroll Restoration

  const viewport = useViewport();
  const root = global?.document?.body.querySelector(ROOT_ELEMENT_SELECTOR);
  const windowWidth = viewport?.width || 0;
  const windowHeight = viewport?.height || 0;

  useLayoutEffect(() => {
    if (!elemContent) {
      return;
    }
    const globalHeight = global?.document?.documentElement?.clientHeight || 0;
    // Keyboard is open
    if(globalHeight > windowHeight) {
      return;
    }
    const bottom = elemContent?.getBoundingClientRect()?.bottom;
    if (bottom > windowHeight) {
      const diff = bottom - windowHeight;
      const top = elemContent?.getBoundingClientRect()?.top;
      elemContent.style.top = top - diff - 2 + "px";
    }
  }, [elemContent, windowHeight]);

  const getVerticalOffset = useCallback(
    (arrow?: "top") => {
      const contentRect = refContent.current?.getBoundingClientRect();
      const contentHeight = contentRect?.height;

      if (!contentHeight || !visualViewport?.height) {
        return;
      }

      let align = !verticalAlign || verticalAlign === "bottom" ? "bottom" : "top";
      const fullHeight = top + height + contentHeight;

      if (visualViewport.height < fullHeight) {
        align = "top";
      }
      if (top < contentHeight) {
        align = "bottom";
      }

      const viewportOffset = viewport?.offsetTop || 0;

      if (align === "bottom") {
        return arrow === "top" ? top + height + viewportOffset + 2 + 10 : top + height + viewportOffset + 2;
      }

      return top - contentHeight + viewportOffset - 2;
    },
    [height, top, verticalAlign, viewport?.offsetTop]
  );

  const initPortal = useCallback(
    ({ children, arrow, className }: { children: any; arrow?: "top"; className?: string }) => {
      const verticalOffset = getVerticalOffset(arrow);

      return root ? (
        createPortal(
          <div className={cn(styles.backdrop, arrow === "top" && styles.arrowTop)} onClick={onHide}>
            <div
              // @ts-ignore
              ref={refContent}
              className={cn(styles.content, !!verticalOffset && styles.ready, className)}
              style={{
                minWidth: width,
                top: (verticalOffset || 0),
                left: !align || align === "left" ? left : undefined,
                right: align === "right" ? windowWidth - right : undefined,
              }}
              onClick={stopEvent}
            >
              {children}
            </div>
          </div>,
          root
        )
      ) : (
        <LoaderCircle size={24} className={styles.loader} />
      );
    },
    [getVerticalOffset, root, onHide, width, align, left, windowWidth, right, stopEvent]
  );

  const Backdrop = useCallback(
    ({ children, arrow, className }: { children: any; arrow?: "top"; className?: string }) =>
      show ? <>{elem && initPortal({ children, arrow, className })}</> : null,
    [elem, initPortal, show]
  );

  return { Backdrop, show, onShow, onHide, elemContent };
};

export default useBackdrop;
