export enum PropertyViewTypes {
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  THUMBNAIL = "thumbnail",
  COLOR = "color",
}

export interface IApiPropertyGroup {
  id: number;
  name: string;
  description: string;
}

export type IApiPropertyGroupsList = IApiPropertyGroup[];

export interface IApiSellerPropertyViewType {
  id: number;
  name: PropertyViewTypes;
  description: string;
  is_default: boolean;
  is_active: boolean;
}

export type IApiSellerPropertyViewTypesList = IApiSellerPropertyViewType[];

interface IApiPropertyDetails {
  id: number;
  name: string;
  is_active: boolean;
  is_custom: boolean;
  as_filter: boolean;
  group: IApiPropertyGroup | null;
  view_type: IApiSellerPropertyViewType | null;
}

export interface IApiCustomerPropertyOfList {
  id: number;
  name: string;
  description: string;
  property_values: {
    id: number;
    title: string;
    description: string;
    measurable_value: string | null;
    is_active: boolean;
  }[];
  group: IApiPropertyGroup | null;
  view_type: IApiSellerPropertyViewType | null;
  is_custom: boolean;
}

export interface IApiCustomerPropertyDetails extends IApiCustomerPropertyOfList {
  // ordering: number;
}

export type IApiCustomerPropertiesList = IApiCustomerPropertyOfList[];

export interface IApiCustomerProductProperty {
  id: number;
  product: number;
  property: IApiPropertyDetails;
  property_value: {
    id: number;
    title: string;
    description: string;
    measurable_value: string | null;
    ordering: number;
    is_custom: boolean;
    store: number;
  };
}

export type IApiCustomerProductPropertiesList = IApiCustomerProductProperty[];

export interface IApiPropertyValueById {
  id: number;
  title: string;
  description: string;
  is_active: boolean;
  measurable_value: string | null;
  property_id: number;
  property_name: string;
  property_view_type_name: PropertyViewTypes | null;
}

export type IApiCustomerPropertiesValuesList = IApiPropertyValueById[];

export interface IApiCustomerProductVariantProperty {
  id: number;
  ordering: number;
  // product: number;
  property: {
    id: number;
    group: IApiPropertyGroup | null;
    property_values: {
      id: number;
      title: string;
      description: string;
      measurable_value: string | null;
      is_active: boolean;
      is_custom: boolean;
      store: number;
      ordering: number;
    }[];
    name: string;
    description: string;
    view_type: IApiSellerPropertyViewType | null;
    is_custom: boolean;
    as_filter: boolean;
  };
  property_value: number;
  variant: number;
}

export type IApiCustomerProductVariantProperties = IApiCustomerProductVariantProperty[];

export interface IApiSellerProperty {
  id: number;
  group: IApiPropertyGroup | null;
  property_values: IApiSellerPropertyValue[];
  name: string;
  description: string;
  single_choice: boolean;
  is_custom: boolean;
  view_type: IApiSellerPropertyViewType | null;
}

export type IApiSellerPropertiesList = IApiSellerProperty[];

export interface IApiSellerPropertyValue {
  id: number;
  title: string;
  description: string;
  ordering: number;
  measurable_value: string | null;
  is_custom: false;
  store: number | null;
  translations: { [locale: string]: string } | null;
}

export interface IApiSellerCategoryProperty {
  property: IApiSellerProperty;
  is_heritable: boolean;
}

export interface IApiSellerProductProperty {
  id: number;
  product: number;
  property: IApiPropertyDetails;
  property_value: IApiSellerPropertyValue;
}

export type IApiSellerProductPropertiesList = IApiSellerProductProperty[];

export interface IApiSellerProductIntent {
  id: number;
  ordering: number;
  product: number;
  property: number;
  property_value: number;
}

export type IApiSellerProductIntentsList = IApiSellerProductIntent[];
