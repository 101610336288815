import cn from "@/libs/cn";
import dynamic from "next/dynamic";
import { TranslateFunc } from "@/hooks/useTranslate";
import { IApiBreadcrumbsList, IApiCategory } from "@/types/interfaces/categories";
import { IApiCategoryMapped } from "@/types/interfaces/aggregated";
import BlockMaxWidth from "@/modules/layout/components/BlockMaxWidth";
import Breadcrumbs from "@/modules/home/components/Breadcrumbs";
import ButtonCategoryShowMore from "./ButtonCategoryShowMore";
import LazyProductsList from "./LazyProductsList";
import HeaderPageCategory from "./HeaderPageCategory";

import styles from "./CommonPageProductsList.module.scss";
import CommonPageSidebar from '@/modules/public/components/CommonPageSidebar';

export interface CommonPageProductsList {
  locale: string;
  translate: TranslateFunc;
  isMobileSize: boolean;
  isSearchPage?: boolean;
  promo?: { id: string; title: string };
  chain?: string[];
  searchParams: { [param: string]: string };
  initQuery: { [param: string]: string | undefined };
  q?: string;
  count: number;
  category?: IApiCategory | null;
  virtualCategory?: IApiCategory | null;
  breadcrumbs?: IApiBreadcrumbsList;
  mappedCategories: IApiCategoryMapped[];
}

export default function CommonPageProductsList(props: CommonPageProductsList) {
  const {
    locale,
    isMobileSize,
    promo,
    translate,
    searchParams,
    initQuery,
    q,
    isSearchPage,
    breadcrumbs,
    category,
    virtualCategory,
    count,
    mappedCategories,
  } = props;
  const { category: categoryId } = searchParams;

  return (
    <BlockMaxWidth>
      <div className={cn(styles.pageProductsList)}>
        {!isMobileSize && (
          <CommonPageSidebar
            promo={promo}
            isSearchPage={isSearchPage}
            mappedCategories={mappedCategories}
            category={category}
            breadcrumbs={breadcrumbs}
            initQuery={initQuery}
            q={q}
            categoryId={categoryId}
          />
        )}
        <div className={styles.content}>
          {!isSearchPage && (
            <Breadcrumbs
              className={styles.breadcrumbs}
              data={breadcrumbs}
              isPageCategory={!!category}
              isPageSearch={isSearchPage}
              promo={promo}
              locale={locale}
              translate={translate}
            />
          )}
          <HeaderPageCategory
            category={category}
            virtualCategory={virtualCategory}
            isSearchPage={isSearchPage}
            q={q}
            count={count}
            mappedCategories={mappedCategories}
            searchParams={searchParams}
            promo={promo}
          />
          <div className={styles.list}>
            <LazyProductsList classNameItem={styles.wrapItem} classNameItemDirty={styles.dirty} />
          </div>
          <ButtonCategoryShowMore />
          {/* Classic pagination by pages (not used)*/}
          {/* <Pagination /> */}
        </div>
      </div>
    </BlockMaxWidth>
  );
}
