"use client";

import useClientContext from "@/context/ClientContext";
import ButtonShowMore from "../ButtonShowMore";
import { useCategoryContext } from "../PageProductsListContext";

interface ButtonCategoryShowMoreProps {
  className?: string;
}

const ButtonCategoryShowMore = (props: ButtonCategoryShowMoreProps) => {
  const { className } = props;
  const { translate } = useClientContext();
  const { ended, onNextPage, loading } = useCategoryContext();

  return !ended ? <ButtonShowMore onClick={() => onNextPage()} loading={loading} className={className} /> : <></>;
};

export default ButtonCategoryShowMore;
