"only-server";
import NextLink from "next/link";
import { LinkProps as NextLinkProps } from "next/link";
import cn from "@/libs/cn";

import styles from "./link.module.scss";
import routesSeller from "@/modules/seller/constants/routesSeller";

interface LinkProps extends Omit<NextLinkProps, "href"> {
  locale?: string;
  href: string;
  scroll?: boolean;
  target?: string;
  module?: string;
  wrapClassName?: string;
  title?: string;
  stopClickEvent?: boolean;
  disabled?: boolean;
  className?: string;
  classNameActive?: string;
  children?: any;
}

const LinkServer = ({
  locale,
  href,
  scroll,
  target,
  module,
  children,
  title,
  stopClickEvent,
  disabled,
  className,
  classNameActive,
  ...rest
}: LinkProps) => {
  const path = href;
  const isSellerPath = path.startsWith(routesSeller.landing());

  let preHref = path;

  if (!preHref.startsWith("http") && !preHref.startsWith("//")) {
    preHref = isSellerPath ? path : locale ? `/${locale}${path}` : path;
    if (process.env.NODE_ENV === "production" && !preHref.startsWith("http")) {
      preHref = `${process.env.NEXT_PUBLIC_DOMAINE_STAGE}${preHref}`;
    }
  }

  return (
    <NextLink
      {...rest}
      href={disabled ? "#" : preHref}
      title={title}
      target={target}
      scroll={scroll}
      className={cn(styles.LinkServer, className)}
    >
      {children}
    </NextLink>
  );
};

export default LinkServer;
