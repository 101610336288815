import { MOCKPromoRecommendedId, MOCKPromoSalesId } from "../pages/promotions";

export const baseRoute = "";

const routesPublic = {
  root: () => `/`,
  category: () => `/category/`,
  // TODO MOCK
  promotionRecommended: () => `/promotions/${MOCKPromoRecommendedId}/`,
  promotionSales: () => `/promotions/${MOCKPromoSalesId}/?sale=1`,
  promotion: (promoId: string) => `/promotions/${promoId}/`,
  search: () => `/search/`,
  seller: () => `/seller/`,
  products: () => `/products/`,
  product: (slug: any) => `/products/${slug}/`,
  stores: () => `/stores/`,
  store: (storeSlug: any) => `/stores/${storeSlug}/`,
  cart: () => `/cart/`,
  help: () => `/help/`,
};

export default routesPublic;

export const getVirtualCategoryURL = (slugs: string[], extraFilters: string | null, source: string[]) => {
  const sourceStr = source.join(",");
  const symbol = `${extraFilters}`.indexOf("?") === -1 ? "?" : "&";

  if (!extraFilters) {
    return `${routesPublic.category()}${slugs.join("/")}/${symbol}source=${sourceStr}`;
  }

  if (extraFilters[0] === "?") {
    const parentSlugs = slugs.slice(0, -1);
    return `${routesPublic.category()}${parentSlugs.join("/")}${extraFilters}${symbol}source=${sourceStr}`;
  }

  if (extraFilters.startsWith("http")) {
    return `${routesPublic.category()}?${extraFilters.replace("http", "")}${symbol}source=${sourceStr}`;
  }

  if (extraFilters.startsWith("/")) {
    return `${extraFilters}${symbol}source=${sourceStr}`;
  }

  return `${routesPublic.category()}?${extraFilters}${symbol}source=${sourceStr}`;
};
