import { useEffect, useState } from "react";

function usePromise<T>(promise: Promise<T>, dafaultValue: T): [T, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<T>(dafaultValue);

  useEffect(() => {
    promise.then((data) => setValue(data));
  }, [promise]);

  return [value, loading];
}

export default usePromise;
