import {
  rangeAllString,
  rangeLastMonthISOString, rangeLastNMonthISOString, rangeLastYearISOString,
  rangeMonthUntilTodayISOString,
  rangeOneMonthISOString, rangeOneYearISOString,
  rangeThisWeekISOString, rangeYearUntilTodayISOString
} from "@/utils/date";
import usePromise from "./usePromise";
import { rangeThisMonthISOString } from "@/utils/date";
import { rangeThisYearISOString } from "@/utils/date";

export enum PERIOD {
  TODAY = "today",
  WEEK = "week",
  CURRENT_MONTH = "current_month",
  MONTH_UNTIL_TODAY = "month_until_today",
  ONE_MONTH = "one_month",
  LAST_MONTH = "last_month",
  LAST_6_MONTH = "last_6_month",
  CURRENT_YEAR = "current_year",
  YEAR_UNTIL_TODAY = "year_until_today",
  ONE_YEAR = "one_year",
  LAST_YEAR = "last_year",
  ALL = "all",
}

export const PERIOD_LABELS = {
  today: 'Today',
  week: 'Current Week',
  current_month: 'Current Month',
  month_until_today: 'This Month',
  one_month: 'One Month',
  last_month: 'Last Month',
  last_6_month: 'Last 6 Months',
  current_year: 'Current Year',
  year_until_today: 'This Year',
  one_year: 'One Year',
  last_year: 'Last Year',
  all: 'All Time',
}

const useDateRangesString = () => {
  const [rangeWeekStr] = usePromise<string>(rangeThisWeekISOString(), "");

  const todayStr = new Date().toISOString().split("T")[0];
  const monthRangeStr = rangeThisMonthISOString(true);
  const monthUntilTodayRangeStr = rangeMonthUntilTodayISOString(true);
  const oneMonthRangeStr = rangeOneMonthISOString(true);
  const lastMonthRangeStr = rangeLastMonthISOString(true);
  const last6MonthRangeStr = rangeLastNMonthISOString(6,true);
  const yearRangeStr = rangeThisYearISOString(true);
  const yearUntilTodayRangeStr = rangeYearUntilTodayISOString(true);
  const oneYearRangeStr = rangeOneYearISOString(true);
  const lastYearRangeStr = rangeLastYearISOString(true);
  const allRangeStr = rangeAllString();

  return {
    [PERIOD.TODAY]: `${todayStr},${todayStr}`,
    [PERIOD.WEEK]: rangeWeekStr,
    [PERIOD.CURRENT_MONTH]: monthRangeStr,
    [PERIOD.MONTH_UNTIL_TODAY]: monthUntilTodayRangeStr,
    [PERIOD.ONE_MONTH]: oneMonthRangeStr,
    [PERIOD.LAST_MONTH]: lastMonthRangeStr,
    [PERIOD.LAST_6_MONTH]: last6MonthRangeStr,
    [PERIOD.CURRENT_YEAR]: yearRangeStr,
    [PERIOD.YEAR_UNTIL_TODAY]: yearUntilTodayRangeStr,
    [PERIOD.ONE_YEAR]: oneYearRangeStr,
    [PERIOD.LAST_YEAR]: lastYearRangeStr,
    [PERIOD.ALL]: allRangeStr,
  };
};

export default useDateRangesString;
