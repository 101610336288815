"use client";
import {RefObject, useLayoutEffect, useState} from 'react';

const TOP_OFFSET = 96;

export const useListSidebarPosition = (
  sideInfoRef: RefObject<HTMLDivElement>
) => {
  const [stickTo, setStickTo] = useState<'top' | 'bottom' | 'top-relative'>('top');

  useLayoutEffect(() => {
    const calculatePosition = () => {
      const sideInfo = sideInfoRef?.current;
      const parent = sideInfo?.parentElement;
      const sideInfoClientRect = sideInfo?.getBoundingClientRect();
      const parentClientRect = parent?.getBoundingClientRect();
      const viewportHeight = document?.documentElement?.clientHeight;
      if(parent && sideInfo && parentClientRect && sideInfoClientRect) {
        const sideInfoHeight = sideInfo.clientHeight;

        let threshold = 0;
        if((sideInfoHeight + TOP_OFFSET) > viewportHeight) {
          threshold = sideInfoHeight - viewportHeight;
        }

        //Side element is less than viewport
        if(threshold === 0) {
          setStickTo('top')
        } else {
          if(parentClientRect.top <= TOP_OFFSET && Math.abs(parentClientRect.top) >= threshold) {
            setStickTo('bottom')
          } else {
            setStickTo('top-relative')
          }
        }
      }
    };
    document?.addEventListener("scroll", calculatePosition, false);
    window?.addEventListener("resize", calculatePosition);

    return () => {
      document?.removeEventListener("scroll", calculatePosition, false);
      window?.removeEventListener("resize", calculatePosition);
    }
  }, [sideInfoRef]);

  return { stickTo };
}
