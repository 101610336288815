import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AxiosInstance } from "axios";
import { wait } from "@/utils/wait";
import { deepCopyObject, hashObject } from "@/utils/data";
import { uniqueArrayProcessing } from "@/utils/array";

const PARAM_NAME_PAGE = "page";
const NONE_NEXT = "None";
const HEADER_PAGINATION_NEXT = "x-pagination-next";

const makeDirty = (arr: any[], dirtyIds: any[]) => {
  const newList = deepCopyObject(arr).map((item: any) =>
    dirtyIds.includes(item.id) ? { ...item, _dirty: true } : item
  );

  return newList;
};

export interface UseLazyLoadList<T> {
  keyReset?: any;
  initList?: T[];
  url: string;
  initRequest?: boolean;
  transformResponse?: (response: any) => T;
  provider?: AxiosInstance;
  byPage?: boolean;
  // for byPage = false with limit & offset
  // x-pagination-next: https://expample/api/v1/public/products/?limit=16&offset=16
  nextPage?: string | null;
  debounce?: number;
  onError?: (text: string) => void;
  stop?: boolean;
}

// Lazyload list with pagination
const useLazyLoadList = <T extends unknown>(args: UseLazyLoadList<T>) => {
  const {
    keyReset,
    initList = [],
    url,
    initRequest,
    transformResponse = (response) => response,
    provider,
    byPage,
    nextPage,
    debounce = 200,
    onError = () => {},
    stop,
  } = args;
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    console.warn("ARGS", args, hashObject(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashObject(args)]);

  const container = useRef({ lazyList: initList });
  const [lazyList, setLazyList] = useState(initList);
  const [next, setNext] = useState<string | number>(byPage ? -1 : (nextPage ? nextPage : NONE_NEXT));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (keyReset) {
      setLazyList(initList);
      container.current.lazyList = initList;
      setNext(-1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyReset]);

  const setList = useCallback((list: T[]) => {
    const tlist = uniqueArrayProcessing(deepCopyObject(list));

    if (tlist.length !== list.length) {
      console.warn("Duplicate items", list);
    }

    // @ts-ignore
    container.current.lazyList = tlist;
    // @ts-ignore
    setLazyList(tlist);
  }, []);

  const reload = useCallback(() => {
    setNext(-1);
  }, []);

  useEffect(() => {
    if (byPage) {
      reload();
    }


    if (initRequest) {
      onNextPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stop, url]);

  const onNextPage = useCallback(
    async (force?: boolean) => {
      if (loading || stop) {
        return;
      }

      let nextURL;

      if (force) {
        setDirty(true);
      }

      if (byPage) {
        if (next === -1 || force) {
          const paramPage = url.match(new RegExp(`${PARAM_NAME_PAGE}=\\d+`));

          let tpage = paramPage?.length ? parseInt(`${paramPage[0].split("=")[1]}`) : false;

          tpage = !tpage || Number.isNaN(tpage) ? 1 : initRequest ? 1 : tpage + 1;

          if (/(\?|\&)page=\d+/.test(url)) {
            nextURL = url.replace(new RegExp(`${PARAM_NAME_PAGE}=\\d+`, "g"), `${PARAM_NAME_PAGE}=${tpage}`);
          } else {
            if (url.indexOf("?") === -1) {
              nextURL = `${url}?page=${initRequest ? 1 : 2}`;
            } else {
              nextURL = `${url}&page=${initRequest ? 1 : 2}`;
            }
          }
        } else if (next && next !== NONE_NEXT) {
          nextURL = next;
          // TODO fixes backend bug
          if (process.env.NODE_ENV === "production") {
            nextURL = `${nextURL}`.replace("http:", "https:");
          }
        }
      } else {
        if (force) {
          nextURL = url;
        } else {
          if (next === -1 && nextPage) {
            // TODO fastfix backend
            if (process.env.NODE_ENV === "production") {
              nextURL = nextPage.replace("http://", "https://");
            }
          }
        }
      }

      if (!nextURL && next && next !== -1) {
        nextURL = next;
      }

      if (nextURL) {
        setLoading(true);
        await wait(debounce);

        let data;

        if (provider) {
          // @ts-ignore
          data = await provider({ url: nextURL, method: "get" }).catch(() => {
            setLoading(false);
          });
        } else {
          data = await fetch(`${nextURL}`);

          if (data.status === 500) {
            console.error("Server error (500)", data);
            onError(`Server error: ${data.url}`);
            setLoading(false);
            return;
          }
        }

        let paginationNext = NONE_NEXT;

        if (data?.status === 200) {
          let tlist;
          if (provider) {
            // @ts-ignore
            let preData = typeof transformResponse === "function" ? transformResponse(data.data) : data.data;
            if (!Array.isArray(preData)) {
              console.warn("List expected", nextURL, data);
              preData = [];
            }
            tlist = dirty
              ?
                [...initList, ...preData]
              : [
                  ...container.current.lazyList,
                  ...preData,
                ];

            // @ts-ignore
            paginationNext = data.headers[HEADER_PAGINATION_NEXT];
          } else {
            // @ts-ignore
            const dataPage = await data.json();
            // @ts-ignore
            paginationNext = data.headers.get(HEADER_PAGINATION_NEXT);

            tlist =
              dirty || force
                ? [...initList, ...transformResponse(dataPage)]
                : [...container.current.lazyList, ...transformResponse(dataPage)];
          }

          setList(tlist);

          // TODO fastfix backend
          setNext(`${paginationNext}`.replace("http://", "https://"));
        } else {
          setNext(NONE_NEXT);
        }
      } else {
        setNext(NONE_NEXT);
      }

      setDirty(false);
      setLoading(false);
    },
    [
      byPage,
      debounce,
      dirty,
      initList,
      initRequest,
      loading,
      next,
      nextPage,
      onError,
      provider,
      setList,
      stop,
      transformResponse,
      url,
    ]
  );

  const mutate = useCallback(
    (arr?: Partial<T>[], dirtyIds?: number[]) => {
      if (!arr) {
        if (Array.isArray(dirtyIds)) {
          setList(makeDirty(lazyList, dirtyIds));
        } else {
          reload();
        }
        return;
      }

      if (Array.isArray(arr)) {
        const newList = deepCopyObject(lazyList);

        arr.forEach((item) => {
          // @ts-ignore
          if (item.id) {
            // @ts-ignore
            const idx = newList.findIndex((i) => i.id == item.id);
            if (idx !== -1) {
              newList[idx] = { ...newList[idx], ...item };
            }
          }
        });

        setList(newList);
      }
    },
    [lazyList, reload, setList]
  );

  return useMemo(
    () => ({
      loading,
      dirty,
      list: lazyList,
      mutate,
      onNextPage,
      ended:
        next === NONE_NEXT ||
        (next === "null" && nextPage === null) ||
        (next === -1 && nextPage === null) ||
        (!initRequest && !lazyList.length),
    }),
    [dirty, initRequest, lazyList, loading, mutate, next, nextPage, onNextPage]
  );
};

export default useLazyLoadList;
