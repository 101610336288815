export const capitalizeFirstLetter = (str = "") => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } catch (err) {
    console.error("capitalizeFirstLetter", typeof str, str);
    return str;
  }
};

export const getPluralTranslationKey = (word: string, count: number, suffix?: string) => {
  const remainder = count % 10;
  if(count === 1 || (remainder === 1 && count > 20)) {
    return `ONE_${word}${suffix || ''}`
  } else if((count >= 2 && count <= 4) || (count > 20 && remainder >= 2 && remainder <= 4)) {
    return `TWO_${word}${suffix || ''}`
  } else {
    return `MANY_${word}${suffix || ''}`
  }
}
