type KeyFuncType<T> = (obj: T) => string | number;

type KeyType<T> = string | KeyFuncType<T>;

const DEFAULT_FIELD = "id";

export const uniqueArrayProcessing = <T extends any>(arr: T[] = [], field?: KeyType<T>) => {
  if (!Array.isArray(arr)) {
    return [];
  }

  if (!arr.length) {
    return [];
  }

  if (typeof arr[0] !== "object") {
    return Array.from(new Set(arr));
  }

  function getKey(item: T) {
    const tkey = field
      ? typeof field === "function"
        ? field(item)
        : // @ts-ignore
          item?.[field]
      : // @ts-ignore
        item?.[DEFAULT_FIELD];

    return tkey;
  }

  const tcont: { [key: string]: T } = {};

  arr.forEach((obj) => {
    const tkey = getKey(obj);

    if (tkey && !tcont[tkey]) {
      tcont[tkey] = obj;
    }
  });

  // Restore ordering
  const t = new Set();
  const newArr: T[] = [];

  // Latest put first
  arr.reverse().forEach((obj) => {
    const tkey = getKey(obj);

    if (!t.has(tkey)) {
      newArr.push(obj);
      t.add(tkey);
    }
  });

  return newArr.reverse();
};
