"use client";
import { createContext, useCallback, useContext, useMemo } from "react";
import { QUERY_SEARCH_PARAM_NAME } from "@/constants/common";
import useLazyLoadListWithFilter from "@/hooks/useLazyLoadListWithFilter";
import { IApiProductAggregated } from "@/types/interfaces/aggregated";
import useClientRouter from "@/hooks/useClientRouter";
import useClientContext from "@/context/ClientContext";

interface CategoryContext {
  list: IApiProductAggregated[];
  onNextPage: (force?: boolean | undefined) => Promise<void>;
  onChangeFilter: (newFilter: { [key: string]: string }) => void;
  onResetFilter: (param?: string | undefined, value?: string | undefined) => void;
  loading: boolean;
  dirty: boolean;
  ended: boolean;
}

export const CategoryContext = createContext<CategoryContext>({
  list: [],
  // @ts-ignore
  onNextPage: () => Promise.resolve(null),
  onChangeFilter: () => {},
  onResetFilter: () => {},
  loading: false,
  dirty: false,
  ended: false,
});

export const useCategoryContext = () => {
  return useContext(CategoryContext);
};

interface PageProductsListContext {
  categoryId?: number | string;
  url: string;
  initQuery: any;
  addApiQueryParams?: any;
  productsAggregated: IApiProductAggregated[];
  nextPage: string | null;
  baseRoute: string;
  children: any;
}

const PageProductsListContext = (props: PageProductsListContext) => {
  const {
    baseRoute,
    categoryId,
    url,
    initQuery,
    addApiQueryParams = {},
    productsAggregated,
    nextPage,
    children,
  } = props;
  const { locale, toast } = useClientContext();

  // TODO QUERY_SEARCH_PARAM_NAME excluded
  const { sortby, q, ...restQuery } = initQuery;
  const search = initQuery[QUERY_SEARCH_PARAM_NAME];
  const router = useClientRouter();

  const onChangeUrl = useCallback(
    (nextRoute: string) => {
      router.replace(nextRoute, { shallow: false, scroll: true });
    },
    [router]
  );

  const { list, onNextPage, onChangeFilter, onResetFilter, loading, dirty, ended } =
    useLazyLoadListWithFilter<IApiProductAggregated>({
      key: router.asPath,
      initQuery: restQuery,
      apiQueryParams: {
        lang: locale,
        category: `${categoryId || ""}`,
        ordering: `${sortby || ""}`,
        search: `${search || ""}`,
        ...addApiQueryParams,
      },
      routeQueryParams: {
        sortby: `${sortby || ""}`,
        [QUERY_SEARCH_PARAM_NAME]: `${search || ""}`,
        // category: categoryId ? `${categoryId}` : "",
      },
      initList: productsAggregated,
      transformResponse: (response) => response.products || [],
      url,
      initRequest: false,
      nextPage,
      onError: toast.error,
      route: baseRoute,
      onChangeUrl,
    });

  const contextValue = useMemo(
    () => ({
      list,
      onNextPage,
      // Example [str]: param1=value1&param2=value2...
      onChangeFilter,
      onResetFilter,
      loading,
      dirty,
      ended,
    }),
    [dirty, ended, list, loading, onChangeFilter, onNextPage, onResetFilter]
  );

  return <CategoryContext.Provider value={contextValue}>{children}</CategoryContext.Provider>;
};

export default PageProductsListContext;
