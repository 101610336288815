import cn from "@/libs/cn";
import React, { useCallback, useMemo, useState } from "react";

import styles from "./styles.module.scss";

interface ToggleSwitch {
  onToggle: (val: boolean, disabled: boolean) => void;
  value?: boolean;
  defaultValue?: boolean;
  title?: string;
  disabled?: boolean;
  switchClassName?: string;
  switchOffClassName?: string;
  toggleClassName?: string;
  toggleOffClassName?: string;
}

const ToggleSwitch = ({
  onToggle,
  value,
  defaultValue = false,
  disabled = false,
  title,
  switchClassName,
  switchOffClassName,
  toggleClassName,
  toggleOffClassName,
}: ToggleSwitch) => {
  const [switchOn, setOn] = useState(defaultValue);

  const onClick = useCallback(() => {
    if (typeof value !== "undefined") {
      onToggle(!value, disabled);
    } else {
      onToggle(!switchOn, disabled);
      if (!disabled) {
        // setOn(!switchOn);
      }
    }
  }, [disabled, value, onToggle, switchOn]);

  const isOn = useMemo(
    () => (typeof value !== "undefined" ? value : switchOn),
    [switchOn, value]
  );

  return (
    <div
      className={cn(styles.toggleSwitch, !isOn && styles.off, !isOn && switchOffClassName, disabled && styles.disabled, switchClassName)}
      title={title}
      onClick={onClick}
    >
      <div className={cn(styles.toggle, disabled && styles.disabled, toggleClassName, !isOn && toggleOffClassName )} />
    </div>
  );
};

export default ToggleSwitch;
