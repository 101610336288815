import { useCallback, useMemo } from "react";
import useClientContext from "@/context/ClientContext";
import FilterCheckbox, { FilterListData } from "./basic/FilterCheckbox";

import styles from "./styles.module.scss";

interface FilterColors {
  data: FilterListData[];
  checked: any[];
  onChange: (checked: any[]) => void;
}

const FilterColors = (props: FilterColors) => {
  const { data, checked, onChange } = props;
  const { translate } = useClientContext();

  const renderItemLabel = useCallback(
    (item: FilterListData) => (
      <div key={item.value} className={styles.labelColor}>
        <div style={{ backgroundColor: item.measurable }} className={styles.color}></div>
        <div className={styles.label} title={item.label}>
          {item.label}
        </div>
      </div>
    ),
    []
  );

  const quantity = useMemo(() => data.reduce((pre, item) => pre + item.quantity, 0), [data]);

  return (
    <FilterCheckbox
      data={data}
      checked={checked}
      quantity={quantity}
      label={translate("Color")}
      onChange={onChange}
      renderItemLabel={renderItemLabel}
      className={styles.colorsWrapper}
    />
  );
};

export default FilterColors;
