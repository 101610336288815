const getStoresFilter = (query: any) => {
  const { category, tags, city, province, search, sortby, ordering, reviews_count, product_count, is_trusted } = query;
  const filter = [];

  if (search) {
    filter.push(`search=${search}`);
  }
  if (sortby) {
    filter.push(`ordering=${sortby}`);
  }
  if (ordering) {
    filter.push(`ordering=${ordering}`);
  }
  if (category) {
    filter.push(`categories=${category}`);
  }
  if (tags) {
    filter.push(`tags=${tags}`);
  }
  if (city) {
    filter.push(`cities=${city}`);
  }
  if (province) {
    filter.push(`provinces=${province}`);
  }
  if (reviews_count) {
    filter.push(`reviews_count=${reviews_count}`);
  }
  if (product_count) {
    filter.push(`product_count=${product_count}`);
  }
  if (is_trusted) {
    filter.push(`is_trusted=${is_trusted}`);
  }

  return filter.join("&");
};

export default getStoresFilter;
