import cn from "@/libs/cn";
import Image, { PropsBaseImage } from "../Image";

import styles from "./styles.module.scss";

interface ImageWithBackgroundProps extends PropsBaseImage {
  disabledBackground?: boolean;
}

const ImageWithBackground = (props: ImageWithBackgroundProps) => {
  const { disabledBackground = true, className, width, height, ...rest } = props;

  return (
    <div className={cn(styles.ImageWithBackground, disabledBackground && styles.disabledBackground, className)}>
      {!disabledBackground && <Image {...rest} alt="background" width={width} height={height} objectFit="cover" />}
      <Image {...rest} alt="picture" width={width} height={height} objectFit="contain" />
    </div>
  );
};

export default ImageWithBackground;
