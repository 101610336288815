"use client";
import React from "react";
import cn from "@/libs/cn";
import useClientContext from "@/context/ClientContext";
import PublicIcons from "@/components/basic/PublicIcon";

import styles from "./styles.module.scss";
import {getPluralTranslationKey} from '@/utils/string';

export interface ISimpleBreadcrumb {}

interface ProductsFoundProps {
  productsCount?: number;
  categoriesCount?: any;
  className?: string;
}

const ProductsFound = (props: ProductsFoundProps) => {
  const { productsCount, categoriesCount, className } = props;
  const { translate, isMobileSize } = useClientContext();

  return (
    <div className={cn(styles.ProductsFound, className)}>
      <span>
        {translate("Found")}{" "}
        <span className={styles.count}>{productsCount || 0}</span>{" "}
        {translate(getPluralTranslationKey("product", productsCount || 0))}{" "}
        {translate("in")}{" "}
        {categoriesCount}{" "}
        {translate(getPluralTranslationKey("category", categoriesCount, '_locative'))}
      </span>
      {isMobileSize ? <PublicIcons name="arrowRight" className={styles.arrow} width={18} height={18} /> : ""}
    </div>
  );
};

export default ProductsFound;
