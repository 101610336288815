"use client";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import useClientContext from "@/context/ClientContext";
import PublicIcons from "@/components/basic/PublicIcon";
import { IApiCategory } from "@/types/interfaces/categories";
import { IApiCategoryMapped } from "@/types/interfaces/aggregated";
import { QUERY_SEARCH_PARAM_NAME } from "@/constants/common";
import useViewport from "@/hooks/useViewport";
import useDisableScroll from "@/hooks/useDisableScroll";
import Checkbox from "@/controls/Checkbox";
import { deepCopyObject } from "@/utils/data";
import Button from "@/controls/Button";
import ToggleSwitch from "@/controls/ToggleSwitch";
import { useThrottleValue } from "@/hooks/useThrottle";
import { getPluralTranslationKey } from "@/utils/string";
import useBackdrop from "@/libs/backdrop/useBackdrop";
import {
  Params,
  filterPriceRangeIsEmpty,
  getFilterParams,
  getInitValuesFromSearch,
} from "../filters/PanelCategoryFilters";
import { useDataPublicCategoryFilters } from "../../hooks/useData";
import FilterPriceRange, { FilterPriceRangeValue } from "../filters/basic/FilterPriceRange";
import { ICategoryFilters } from "../../pages/api/category-filters.api";
import { useCategoryContext } from "../PageProductsListContext";
import cn from "@/libs/cn";

import styles from "./styles.module.scss";
import dynamic from 'next/dynamic';

const PanelSelectedFilters = dynamic(() => import("./PanelSelectedFilters"), { ssr: false });

interface MobileCategoryFiltersProps {
  category?: IApiCategory | null;
  showDinamicFilters?: boolean;
  showBrands?: boolean;
  searchParams: { [param: string]: string };
  mappedCategories: IApiCategoryMapped[];
}

export function getFiltersQuantity(query?: { [param: string]: string }) {
  if (!query) {
    return 0;
  }
  const { properties, ...search } = query;
  return Object.keys(search).length + ((properties || "").match(/\:/g) || []).length;
}

const MobileCategoryFilters = (props: MobileCategoryFiltersProps) => {
  const { category, showDinamicFilters, showBrands, searchParams } = props;
  const { isMobileSize, translate } = useClientContext();

  const { q, sortby, min_price, ...rest } = searchParams || {};
  const quantity = getFiltersQuantity(rest);

  const refModal = useRef(null);
  const { Backdrop, onHide, onShow, show } = useBackdrop({ elem: refModal.current, hideWhenResizing: false });
  useDisableScroll(show);

  // Hide when resizing to larger
  useEffect(() => {
    if (!isMobileSize && show) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileSize]);

  if (!isMobileSize) {
    return null;
  }

  return (
    <div className={styles.MobileCategoryFilters}>
      <div ref={refModal} className={styles.button} onClick={onShow}>
        <PublicIcons name="filter" width={16} height={16} />
        <div>
          {translate("Filters")} {!!quantity && <span className={styles.quantity}>{quantity}</span>}
        </div>
      </div>
      <Backdrop className={styles.content}>
        <MobileFilterContextProvider
          category={category}
          showDinamicFilters={showDinamicFilters}
          searchParams={searchParams}
        >
          <MobileModalCategoryFilters
            showBrands={showBrands}
            show={show}
            onShow={(val) => (val ? onShow() : onHide())}
            category={category}
            searchParams={searchParams}
          />
        </MobileFilterContextProvider>
      </Backdrop>
    </div>
  );
};

export default MobileCategoryFilters;

export interface FilterListItem {
  type: "price" | "brands" | "property" | "in_stock" | "sale" | "is_ready_to_shipping";
  id?: string;
  label: string;
  values: { id: number; title: string; measurable_value?: string | null; count: number }[];
  selected: string[];
  checked?: boolean;
  placeholder?: string;
}

const mobileFilterContextDefault: {
  isLoading: boolean;
  categoryFiltersList: ICategoryFilters;
  initValues: Params;
  filterIdx: string | null;
  setFilterIdx: (idx: string | null) => void;
  filters: FilterListItem[];
  draftPrice: React.MutableRefObject<FilterPriceRangeValue>;
  onCheckedDraft: (checked: boolean, idx: number, valueId: string, filter: FilterListItem) => void;
  onApplyDraft: () => void;
  onToggleDraft: (type: "in_stock" | "sale" | "is_ready_to_shipping", active: boolean) => void;
  onResetDraft: () => void;
  onResetFilters: () => void;
  onShowProducts: () => void;
  onCleanFilters: () => void;
  onResetFilter: (param: "brand" | "property" | "price" | "sale" | "free_delivery" | "in_stock" | "is_ready_to_shipping", id?: string) => void;
} = {
  isLoading: false,
  categoryFiltersList: {
    count: 0,
    brands: [],
    categories: [],
    category_ancestors: [],
    max_price: null,
    min_price: null,
    properties: [],
    status: 200,
    statusText: "default",
    stores: [],
    url: "",
  },
  initValues: {
    free_delivery: false,
    in_stock: false,
    is_ready_to_shipping: false,
    sale: false,
  },
  filterIdx: null,
  setFilterIdx: (idx) => {},
  filters: [],
  draftPrice: { current: {} },
  onCheckedDraft: () => {},
  onToggleDraft: (type: "in_stock" | "sale" | "is_ready_to_shipping", active: boolean) => {},
  onApplyDraft: () => {},
  onResetDraft: () => {},
  onResetFilters: () => {},
  onShowProducts: () => {},
  onCleanFilters: () => {},
  onResetFilter: (param: "brand" | "property" | "price" | "sale" | "free_delivery" | "in_stock" | "is_ready_to_shipping", id?: string) => {},
};

const MobileFilterContext = React.createContext(mobileFilterContextDefault);

const useMobileFilterContext = () => useContext(MobileFilterContext);

interface MobileFilterContextProviderProps {
  category?: IApiCategory | null;
  showDinamicFilters?: boolean;
  searchParams: { [param: string]: string };
  children: any;
}

const MobileFilterContextProvider = (props: MobileFilterContextProviderProps) => {
  const { children, category, showDinamicFilters, searchParams } = props;
  const { category: categoryId, brand, properties, sale } = searchParams;
  const { translate } = useClientContext();
  const { onChangeFilter, onResetFilter: onResetFilterOut } = useCategoryContext();

  const search = searchParams?.[QUERY_SEARCH_PARAM_NAME];
  /*
  const { data: categoryFiltersList } = useDataPublicCategoryFilters({
    categoryId: categoryId || category?.id,
    brand,
    properties,
    searchStr: search,
    sale: sale ? "1" : undefined,
  });
  */
  const initValues = useMemo(() => getInitValuesFromSearch(searchParams), [searchParams]);
  const [values, setValues] = useState(initValues);
  const draftPrice = useRef<FilterPriceRangeValue>(initValues.price || {});
  const [draftValues, setDarftValues] = useState(initValues);
  const min = useThrottleValue(1000, draftPrice.current.min);
  const max = useThrottleValue(1000, draftPrice.current.max);

  const { data: categoryFiltersList, isLoading } = useDataPublicCategoryFilters({
    categoryId: categoryId || category?.id,
    brand: draftValues.brands?.join(","),
    properties: getFilterParams(draftValues).properties,
    searchStr: search,
    sale: draftValues.sale ? "1" : undefined,
    min_price: min,
    max_price: max,
    is_ready_to_shipping: draftValues.is_ready_to_shipping ? '1' : undefined,
  });

  const filters = useMemo(() => {
    const last = (category && !category?.child.length) || showDinamicFilters;
    const arr: FilterListItem[] = [];

    arr.push({
      type: "price",
      label: translate("Price"),
      values: [],
      selected: [],
    });

    // arr.push({
    //   type: "in_stock",
    //   label: translate("In stock"),
    //   values: [],
    //   selected: [],
    //   checked: draftValues.in_stock,
    // });

    arr.push({
      type: "is_ready_to_shipping",
      label: translate("Ready for shipping"),
      values: [],
      selected: [],
      checked: draftValues.is_ready_to_shipping,
    });

    arr.push({
      type: "sale",
      label: translate("Sale"),
      values: [],
      selected: [],
      checked: draftValues.sale,
    });

    if (categoryFiltersList.brands.length) {
      arr.push({
        type: "brands",
        label: translate("Brand"),
        values: categoryFiltersList.brands.map((i) => ({ id: i.id, title: i.name, count: i.doc_count })),
        selected: draftValues.brands || [],
        placeholder: translate("All brands"),
      });
    }

    if (last) {
      categoryFiltersList.properties.forEach((p) => {
        if (p.property_values.length) {
          arr.push({
            type: "property",
            id: `${p.id}`,
            label: translate(p.name),
            values: p.property_values.map((i) => ({
              id: i.id,
              title: i.title,
              measurable_value: i.measurable_value,
              count: i.doc_count,
            })),
            selected: draftValues.propsChecked?.[p.id] || [],
            placeholder: translate("All options"),
          });
        }
      });
    }

    return arr;
  }, [
    category,
    showDinamicFilters,
    translate,
    // draftValues.in_stock,
    draftValues.is_ready_to_shipping,
    draftValues.sale,
    draftValues.brands,
    draftValues.propsChecked,
    categoryFiltersList.brands,
    categoryFiltersList.properties,
  ]);

  const onCheckedDraft = useCallback(
    (checked: boolean, idx: number, valueId: string, filter: FilterListItem) => {
      const newDraftValues = deepCopyObject(draftValues) as Params;
      const newSelected = checked
        ? [...filters[idx].selected, valueId]
        : filters[idx].selected.filter((id) => id !== valueId);

      if (filter.type === "brands") {
        newDraftValues.brands = newSelected;
      } else if (filter.type === "property") {
        if (newDraftValues.propsChecked && filter.id) {
          newDraftValues.propsChecked[filter.id] = newSelected;
        }
      }

      setDarftValues(newDraftValues);
    },
    [filters, draftValues]
  );

  const onApplyDraft = useCallback(
    (forceValues?: any) => {
      setValues(forceValues || draftValues);
    },
    [draftValues]
  );

  const onToggleDraft = useCallback(
    (type: "in_stock" | "sale" | "is_ready_to_shipping", active: boolean) => {
      const newDraftValues = deepCopyObject(draftValues) as Params;
      // @ts-ignore
      newDraftValues[type] = active ? true : undefined;
      setDarftValues(newDraftValues);
      onApplyDraft(newDraftValues);
    },
    [draftValues, onApplyDraft]
  );

  const onResetDraft = useCallback(() => {
    setDarftValues(values);
  }, [values]);

  const [filterIdx, setFilterIdx] = useState<string | null>(null);

  const onShowProducts = useCallback(() => {
    onChangeFilter(getFilterParams({ ...values, price: draftPrice.current }));
  }, [onChangeFilter, values]);

  const onResetFilters = useCallback(
    (force?: boolean) => {
      draftPrice.current = force ? {} : initValues.price || {};
      setValues(force ? {} : initValues);
      setDarftValues(force ? {} : initValues);
    },
    [initValues]
  );

  const onCleanFilters = useCallback(() => {
    onResetFilters(true);
    onChangeFilter({});
  }, [onChangeFilter, onResetFilters]);

  const onResetFilter = useCallback((param: "brand" | "property" | "price" | "sale" | "free_delivery" | "in_stock" | "is_ready_to_shipping", id?: string) => {
    const newDraftValues = deepCopyObject(draftValues) as Params;
    if(param === 'price') {
      draftPrice.current = initValues.price || {};
      newDraftValues.price = {};
    } else if(param === 'brand') {
      newDraftValues.brands = [];
    } else if(param === 'sale' || param === 'free_delivery' || param === 'in_stock' || param === 'is_ready_to_shipping') {
      newDraftValues[param] = false;
    } else if(param === 'property' && id && newDraftValues.propsChecked) {
      delete newDraftValues.propsChecked[id];
    }

    setDarftValues(newDraftValues);
    onApplyDraft(newDraftValues);
  }, [draftValues, initValues.price, onApplyDraft]);

  const contextValue = useMemo(
    () => ({
      isLoading,
      categoryFiltersList,
      initValues,
      filters,
      filterIdx,
      setFilterIdx,
      draftPrice,
      onCheckedDraft,
      onApplyDraft,
      onToggleDraft,
      onResetDraft,
      onResetFilters,
      onShowProducts,
      onCleanFilters,
      onResetFilter,
    }),
    [
      isLoading,
      categoryFiltersList,
      initValues,
      filters,
      filterIdx,
      onCheckedDraft,
      onApplyDraft,
      onToggleDraft,
      onResetDraft,
      onResetFilters,
      onShowProducts,
      onCleanFilters,
      onResetFilter,
    ]
  );

  return <MobileFilterContext.Provider value={contextValue}>{children}</MobileFilterContext.Provider>;
};

interface MobileModalCategoryFiltersProps {
  showBrands?: boolean;
  show: boolean;
  onShow: (show: boolean) => void;
  category?: IApiCategory | null;
  searchParams: { [param: string]: string };
}

const MobileModalCategoryFilters = (props: MobileModalCategoryFiltersProps) => {
  const { showBrands, onShow, category, searchParams } = props;
  const { translate } = useClientContext();
  const viewport = useViewport();

  const {
    isLoading,
    categoryFiltersList,
    draftPrice,
    filters,
    filterIdx,
    initValues,
    setFilterIdx,
    onCheckedDraft,
    onApplyDraft,
    onResetDraft,
    onResetFilters,
    onShowProducts,
    onCleanFilters,
    onResetFilter,
  } = useMobileFilterContext();

  const { count } = categoryFiltersList;

  const filter = useMemo(() => {
    if (filterIdx === null) {
      return null;
    }
    return filters.find((item) => `${item.type}_${item.id}` === filterIdx) || null;
  }, [filterIdx, filters]);

  const refHeader = useRef<HTMLDivElement>(null);

  const quantityActiveFilters = useMemo(() => {
    let count = filters.filter((i) => i.selected.length || i.checked).length;
    if (draftPrice.current.min) {
      count++;
    }
    return count;
  }, [draftPrice, filters]);

  const modalTitle = useMemo(() => {
    if (filter) {
      if (!filter.selected.length) {
        return filter.label;
      }

      return (
        <span>
          {filter.label} <span className={styles.quantity}>({filter.selected.length})</span>
        </span>
      );
    }

    if (quantityActiveFilters) {
      return (
        <span>
          {translate("Filters")} <span className={styles.quantity}>({quantityActiveFilters})</span>
        </span>
      );
    }

    return translate("Filters");
  }, [filter, quantityActiveFilters, translate]);

  const scrollToTop = useCallback(() => {
    refHeader.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const onCloseModal = useCallback(() => {
    onShow(false);
    onResetFilters();
    scrollToTop();
  }, [onResetFilters, scrollToTop, onShow]);

  return (
    <div
      className={cn(styles.MobileModalCategoryFilters, isLoading && styles.loading)}
      style={{ maxHeight: viewport?.height }}
    >
      <div ref={refHeader} className={styles.modalTitle}>
        {filterIdx !== null ? (
          <PublicIcons
            name="arrowShortLeft"
            width={24}
            height={24}
            onClick={() => {
              if (filterIdx !== null) {
                setFilterIdx(null);
                onResetDraft();
              }
            }}
          />
        ) : (
          <div />
        )}
        <div>{modalTitle}</div>
        <PublicIcons name="close2" width={24} height={24} onClick={onCloseModal} />
      </div>
      {filterIdx === null ? (
        <>
          <div className={styles.wrapContent} data-scrollable="1">
            {
              !!quantityActiveFilters && (
                <div className={styles.selectedFilters}>
                  <div className={styles.selectedFiltersTitle}>
                    {translate("Selected filters")}:
                  </div>
                  <PanelSelectedFilters
                    onReset={onResetFilter}
                    quantityActiveFilters={quantityActiveFilters}
                    filters={filters}
                    minPrice={draftPrice.current.min}
                    maxPrice={draftPrice.current.max}
                  />
                </div>
              )
            }
            {filters.map((i, idx) => {
              if (i.type === "brands" && showBrands) {
                const vals = i.values.filter((v) => i.selected.includes(`${v.id}`));

                return (
                  <React.Fragment key={`${i.type}-${i.id}`}>
                    <div
                      className={styles.property}
                      onClick={() => {
                        setFilterIdx(`${i.type}_${i.id}`);
                        scrollToTop();
                      }}
                    >
                      <div className={styles.wrap}>
                        <div className={cn(styles.label, !!vals.length && styles.bold)}>{i.label}</div>
                        <div className={cn(styles.value, vals.length && styles.valueSelected)}>
                          {vals.length ? vals.map((v) => v.title).join(", ") : i.placeholder}
                        </div>
                      </div>
                      <div className={styles.wrapArrow}>
                        <PublicIcons name="arrowRight" width={24} height={24} />
                      </div>
                    </div>
                    {idx <= filters.length - 2 && <div className={styles.div} />}
                  </React.Fragment>
                );
              }

              if (i.type === "price") {
                return (
                  <React.Fragment key="price">
                    <MobileFilterPrice />
                  </React.Fragment>
                );
              }

              if (i.type === "in_stock" || i.type === "sale" || i.type === "is_ready_to_shipping") {
                return (
                  <React.Fragment key={i.type}>
                    <MobileFilterToggle label={i.label} name={i.type} value={i.checked} />
                    <div className={styles.div} />
                  </React.Fragment>
                );
              }

              return null;
            })}
            <div className={styles.wrapProperties}>
              {filters.map((i, idx) => {
                if (i.type !== "property") {
                  return null;
                }

                const vals = i.values.filter((v) => i.selected.includes(`${v.id}`));

                return (
                  <React.Fragment key={`${i.type}-${i.id}`}>
                    <div
                      className={styles.property}
                      onClick={() => {
                        setFilterIdx(`${i.type}_${i.id}`);
                        scrollToTop();
                      }}
                    >
                      <div className={styles.wrap}>
                        <div className={cn(styles.label, !!vals.length && styles.bold)}>{i.label}</div>
                        <div className={cn(styles.value, vals.length && styles.valueSelected)}>
                          {vals.length ? vals.map((v) => v.title).join(", ") : i.placeholder}
                        </div>
                      </div>
                      <div className={styles.wrapArrow}>
                        <PublicIcons name="arrowRight" width={24} height={24} />
                      </div>
                    </div>
                    {idx <= filters.length - 2 && <div className={styles.div} />}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            {!!Object.keys(initValues).length && (
              <Button
                color="warning"
                // width="100%"
                className={styles.buttonReset}
                variant="text"
                onClick={() => {
                  onCleanFilters();
                  onShow(false);
                  scrollToTop();
                }}
              >
                {translate("Reset")}
              </Button>
            )}
            <Button
              color="primary"
              // width="100%"
              className={styles.buttonShow}
              onClick={() => {
                onShowProducts();
                onShow(false);
                scrollToTop();
              }}
              loading={isLoading}
            >
              {translate("Show {COUNT} {PRODUCTS}", {
                COUNT: count || 0,
                PRODUCTS: translate(getPluralTranslationKey("product", count)),
              })}
            </Button>
          </div>
        </>
      ) : (
        <div className={cn(styles.wrapContentList)}>
          <div className={styles.div} />
          <div className={styles.checkList} data-scrollable="1">
            {filter?.values.map((i) => (
              <Checkbox
                key={`${filter.type}-${i.id}`}
                label={
                  <div>
                    {i.title} <span className={styles.count}>({i.count})</span>
                  </div>
                }
                checked={filter.selected.includes(`${i.id}`)}
                onChange={(e) =>
                  onCheckedDraft(
                    e.target.checked,
                    filters.findIndex((item) => `${item.type}_${item.id}` === filterIdx),
                    `${i.id}`,
                    filter
                  )
                }
              />
            ))}
            <div style={{ flex: 1 }} />
          </div>
          <Button
            color="primary"
            width="100%"
            onClick={() => {
              setFilterIdx(null);
              onApplyDraft();
              scrollToTop();
            }}
            loading={isLoading}
            className={styles.buttonApply}
          >
            {translate("Apply")}
          </Button>
        </div>
      )}
    </div>
  );
};

export function isEqualRangePrice(exist: FilterPriceRangeValue, value: FilterPriceRangeValue) {
  if (!exist.min || !exist.max) {
    return false;
  }
  return (
    Math.trunc(parseFloat(`${exist.min}`)) === Math.trunc(parseFloat(`${value.min}`)) &&
    Math.ceil(parseFloat(`${exist.max}`)) === Math.ceil(parseFloat(`${value.max}`))
  );
}

const MobileFilterPrice = () => {
  const { translate } = useClientContext();
  const { categoryFiltersList, draftPrice } = useMobileFilterContext();
  const [value, setValue] = useState(draftPrice.current);

  const onDraftPrice = useCallback(
    (draft: FilterPriceRangeValue) => {
      if (
        isEqualRangePrice(
          { min: categoryFiltersList.min_price || undefined, max: categoryFiltersList.max_price || undefined },
          draft
        )
      ) {
        draftPrice.current = {};
      } else {
        draftPrice.current = draft;
      }
      setValue(draft);
    },
    [categoryFiltersList.max_price, categoryFiltersList.min_price, draftPrice]
  );

  useEffect(() => {
    setValue(draftPrice.current);
  }, [draftPrice.current]);

  const minPrice = useMemo(() => {
    if (!categoryFiltersList.min_price) {
      return undefined;
    }
    const v = parseInt(`${categoryFiltersList.min_price}`);
    return !Number.isNaN(v) ? `${v}` : undefined;
  }, [categoryFiltersList.min_price]);

  const maxPrice = useMemo(() => {
    if (!categoryFiltersList.max_price) {
      return undefined;
    }
    const v = Number(`${categoryFiltersList.max_price}`);
    return !Number.isNaN(v) ? `${Math.ceil(v)}` : undefined;
  }, [categoryFiltersList.max_price]);

  if (filterPriceRangeIsEmpty(minPrice, maxPrice)) {
    return null;
  }

  return (
    <>
      <FilterPriceRange
        label={translate("Price")}
        value={value}
        min={minPrice}
        max={maxPrice}
        onChange={onDraftPrice}
        hideButtonApply
      />
      <div className={styles.div} />
    </>
  );
};

interface MobileFilterToggleProps {
  label: string;
  name: "in_stock" | "sale" | "is_ready_to_shipping";
  value?: boolean;
}

const MobileFilterToggle = (props: MobileFilterToggleProps) => {
  const { name, label, value: initValue } = props;
  const { onToggleDraft } = useMobileFilterContext();
  const [value, setValue] = useState(initValue);

  const onToggle = useCallback(
    (val: boolean) => {
      setValue(val);
      onToggleDraft(name, val);
    },
    [name, onToggleDraft]
  );

  return (
    <div className={styles.MobileFilterToggle}>
      <div>{label}</div>
      <ToggleSwitch onToggle={onToggle} value={value} />
    </div>
  );
};
