"use client";
import React from "react";
import cn from "@/libs/cn";
import useClientContext from "@/context/ClientContext";
import Button from "@/controls/Button";

import styles from "./styles.module.scss";
import { TranslateFunc } from "@/hooks/useTranslate";

interface ButtonShowMore {
  href?: string;
  target?: string;
  // translate: TranslateFunc;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
  variant?: "text" | "outlined";
  isAtFooter?: boolean;
}

const ButtonShowMore = ({
  href,
  target,
  onClick,
  // translate,
  loading,
  className,
  variant = "outlined",
  isAtFooter = true,
}: ButtonShowMore) => {
  const { translate } = useClientContext();

  return (
    <div className={cn(styles.ButtonShowMore, isAtFooter && styles.footerMargin, className)}>
      <Button
        href={href}
        target={target}
        color="secondary"
        type="link"
        variant={variant}
        className={cn(
          styles.button,
          variant === "text" && styles.variantText,
          loading && styles.loading,
        )}
        loaderColor="#828B94"
        loading={loading}
        onClick={onClick}
      >
        {translate("Show more")}
      </Button>
    </div>
  );
};

export default ButtonShowMore;
