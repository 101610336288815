"use client";
import React, { useCallback, useRef } from "react";
import cn from "@/libs/cn";
import useBackdrop from "@/libs/backdrop/useBackdrop";
import NativeSelect from "@/components/NativeSelect";

import styles from "./styles.module.scss";

interface SelectProps {
  options: { value: any; label: string; customLabel?: any }[];
  value?: any;
  onChange: (value: any, isCustom?: boolean) => void;
  children: any;
  content?: any;
  textAlign?: "center";
  align?: "right" | "left" | undefined;
  className?: string;
  classNameOptions?: string;
  asNative?: boolean;
}

const Select = (props: SelectProps) => {
  const { children, textAlign, align, options, value, content, onChange, asNative, className, classNameOptions } =
    props;
  const ref = useRef(null);

  const { Backdrop, onHide, onShow, show } = useBackdrop({ elem: ref.current, align });

  const onSelect = useCallback(
    (val: any, isCustom?: boolean) => {
      onHide();
      onChange(val, isCustom);
    },
    [onChange, onHide]
  );

  return (
    <div ref={ref} className={cn(styles.Select, show && styles.show, className)} onClick={onShow}>
      <div className={cn(styles.value, textAlign === "center" && styles.center)}>{children}</div>
      {asNative ? (
        <NativeSelect
          options={options.map((i) => ({ value: i.value, title: i.label }))}
          onChange={onSelect}
          value={value}
        />
      ) : (
        <Backdrop className={cn(styles.options, classNameOptions)}>
          {content
            ? content
            : options.map((item) => (
                <div
                  key={item.value}
                  className={cn(styles.option)}
                  onClick={() => onSelect(item.value, !!item.customLabel)}
                >
                  {item.customLabel ? item.customLabel : item.label}
                </div>
              ))}
        </Backdrop>
      )}
    </div>
  );
};

export default Select;
