import { NextPageContext } from "next";
import { parseEnvInt } from "./data";
import { wait } from "./wait";

const NEXT_REVALIDATE_TIME = parseEnvInt(process.env.NEXT_REVALIDATE_TIME, 60) // in seconds

export interface PerfomanceRequest {
  url: string;
  startTime: number;
  endTime?: number;
  value?: number;
  status?: number;
}

export interface ContextPerfomance extends NextPageContext {
  _performanceRequests?: PerfomanceRequest[];
}

function setPerformanceRequest(args: { context: ContextPerfomance; url: string; end: boolean; response?: Response }) {
  const { context, url, end, response } = args;
  if (!Array.isArray(context._performanceRequests)) {
    context._performanceRequests = [];
  }

  if (!end) {
    if (context._performanceRequests.findIndex((i) => i.url === url) !== -1) {
      console.error("Duplicate request", url);
    }

    context._performanceRequests.push({ url, startTime: Date.now() });
  } else {
    const dataIdx = context._performanceRequests.findIndex((i) => i.url === url);
    if (dataIdx !== -1) {
      const endTime = Date.now();
      context._performanceRequests[dataIdx].endTime = endTime;
      context._performanceRequests[dataIdx].value = endTime - context._performanceRequests[dataIdx].startTime;

      if (response) {
        context._performanceRequests[dataIdx].status = response.status;
      }
    } else {
      console.error("Not found request data", url, context._performanceRequests);
    }
  }
}

export function startPerformanceRequest(context: NextPageContext, url: string) {
  setPerformanceRequest({ context, url, end: false });
}

export function endPerformanceRequest(context: NextPageContext, url: string) {
  setPerformanceRequest({ context, url, end: true });
}

export async function nodeFetch(
  context: NextPageContext,
  url: string,
  nextConfig?: NextFetchRequestConfig,
  cacheConfig?: RequestCache
): Promise<Response> {
  setPerformanceRequest({ context, url, end: false });
  let options = { next: { revalidate: NEXT_REVALIDATE_TIME, ...(nextConfig || {}) }, headers: {}, cache: cacheConfig };
  const testOptions = {next:{}, headers: {}, cache: 'force-cache'};
  if(context.locale) {
    options.headers = {
      ["Accept-Language"]: context.locale,
    }
  }
  const response = await fetch(url, options);
  setPerformanceRequest({ context, url, end: true, response });
  return response;
}
