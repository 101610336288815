export const libDays = async () => {
  const dayjs = (await import("dayjs")).default;
  const weekday = (await import("dayjs/plugin/weekday")).default;
  const isoWeek = (await import("dayjs/plugin/isoWeek")).default;
  dayjs.extend(weekday);
  dayjs.extend(isoWeek);
  return dayjs;
};

export const addingZero = (val: number) => `${val > 9 ? val : `0${val}`}`;

export const timestampToISOString = (date: number, withoutTime?: boolean) => {
  const current = new Date(date);

  try {
    return withoutTime
      ? current.toISOString().split("T")[0]
      : current.toISOString();
  } catch (err) {
    console.error("timestampToISOString", err, date);
    return "";
  }
};

// // Today
//
// export const timestampToday = () => {
//   const current = new Date(Date.now());
//   let year = current.getFullYear();
//   let month = current.getMonth();
//   let day = current.getDay();
//
//   const timestamp = new Date(`${year}-${addingZero(month + 1)}-${day}`).getTime();
//
//   return timestamp;
// };

// This month

export const timestampStartThisMonth = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();

  const timestamp = new Date(`${year}-${addingZero(month + 1)}-01`).getTime();

  return timestamp;
};

export const getThisMonthMilliseconds = () => {
  const current = new Date(Date.now());
  const year = current.getFullYear();
  const month = current.getMonth();

  try {
    const timestamp =
      new Date(`${year}-${addingZero(month + 2)}-01`).getTime() -
      1 -
      new Date(`${year}-${addingZero(month + 1)}-01`).getTime() +
      current.getTimezoneOffset() * 60 * 1000;

    return timestamp;
  } catch (err) {
    console.error("getThisMonthMilliseconds", err);
    return 0;
  }
};

// Last month

export const timestampStartLastMonth = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();

  if (month > 0) {
    month -= 1;
  } else {
    month = 11;
    year -= 1;
  }

  return new Date(`${year}-${addingZero(month + 1)}-01`).getTime();
};

// Last month

export const getLastMonthMilliseconds = () => {
  const startTimestamp = timestampStartLastMonth();

  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();

  if (month > 0) {
    month -= 1;
  } else {
    month = 11;
    year -= 1;
  }

  const endTimestamp =
    new Date(`${year}-${addingZero(month + 2)}-01`).getTime() -
    1 -
    current.getTimezoneOffset() * 60 * 1000;

  return endTimestamp - startTimestamp;
};

export const timestampOneMonthAgo = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();
  let day = current.getDate();

  if (month > 0) {
    month -= 1;
  } else {
    month = 11;
    year -= 1;
  }

  return new Date(`${year}-${addingZero(month + 1)}-${day}`).getTime();
};

export const timestampNMonthAgo = (n: number) => {
  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();
  let day = current.getDate();

  const diff = month - n;
  if (diff >= 0) {
    month -= n;
  } else {
    month = 11 - Math.abs(diff);
    year -=  Math.floor(Math.abs(diff) / 12) + 1;
  }

  return new Date(`${year}-${addingZero(month + 1)}-${day}`).getTime();
};

export const timestampOneYearAgo = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();
  let month = current.getMonth();
  let day = current.getDate();

  year -= 1;

  return new Date(`${year}-${addingZero(month + 1)}-${day}`).getTime();
};


// This year

export const timestampStartThisYear = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();

  return new Date(`${year}-01-01`).getTime();
};

export const getThisYearMilliseconds = () => {
  const current = new Date(Date.now());
  const year = current.getFullYear();

  return (
    new Date(`${year + 1}-01-01`).getTime() -
    1 -
    new Date(`${year}-01-01`).getTime()
  );
};

// Last year

export const timestampStartLastYear = () => {
  const current = new Date(Date.now());
  let year = current.getFullYear();

  return new Date(`${year - 1}-01-01`).getTime();
};

export const getLastYearMilliseconds = () => {
  const startTimestamp = timestampStartLastYear();

  const current = new Date(Date.now());
  let year = current.getFullYear();

  const endTimestamp = new Date(`${year}-01-01`).getTime() - 1;

  return endTimestamp - startTimestamp;
};

// Range

export const rangeThisWeekISOString = async () => {
  const dayjs = await libDays();

  const start = dayjs().startOf("isoWeek").format("YYYY-MM-DD");
  const end = dayjs().endOf("isoWeek").format("YYYY-MM-DD");

  return `${start},${end}`;
};

export const rangeThisMonthISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartThisMonth(),
    withoutTime
  )},${timestampToISOString(
    timestampStartThisMonth() + getThisMonthMilliseconds(),
    withoutTime
  )}`;

export const rangeMonthUntilTodayISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartThisMonth(),
    withoutTime
  )},${timestampToISOString(
    Date.now(),
    withoutTime
  )}`;

export const rangeOneMonthISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampOneMonthAgo(),
    withoutTime
  )},${timestampToISOString(
    Date.now(),
    withoutTime
  )}`;

export const rangeLastMonthISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartLastMonth(),
    withoutTime
  )},${timestampToISOString(
    timestampStartLastMonth() + getLastMonthMilliseconds(),
    withoutTime
  )}`;

export const rangeLastNMonthISOString = (n: number, withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampNMonthAgo(n),
    withoutTime
  )},${timestampToISOString(
    Date.now(),
    withoutTime
  )}`;

export const rangeThisYearISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartThisYear(),
    withoutTime
  )},${timestampToISOString(
    timestampStartThisYear() + getThisYearMilliseconds(),
    withoutTime
  )}`;

export const rangeYearUntilTodayISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartThisYear(),
    withoutTime
  )},${timestampToISOString(
    Date.now(),
    withoutTime
  )}`;

export const rangeOneYearISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampOneYearAgo(),
    withoutTime
  )},${timestampToISOString(
    Date.now(),
    withoutTime
  )}`;

export const rangeLastYearISOString = (withoutTime?: boolean) =>
  `${timestampToISOString(
    timestampStartLastYear(),
    withoutTime
  )},${timestampToISOString(
    timestampStartLastYear() + getLastYearMilliseconds(),
    withoutTime
  )}`;

export const rangeYearXISOString = (yearToRange: number, withoutTime?: boolean) => {
  const timestampStartYearX = new Date(`${yearToRange}-01-01`).getTime();
  const timestampEndYearX = new Date(`${yearToRange}-12-31`).getTime();
  return `${timestampToISOString(
    timestampStartYearX,
    withoutTime
  )},${timestampToISOString(
    timestampEndYearX,
    withoutTime
  )}`;
}


// ALL
export const rangeAllString = () => `,`;

  // Operation
  export const dateDiff = (start?: string, end?: string) => {
    const startTime = (new Date(start || "")).getTime();
    const endTime = (new Date(end || "")).getTime();

    if (startTime === endTime) {
      return 0;
    }

    return endTime - startTime;
  }
