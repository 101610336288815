import React, { useMemo } from "react";

import styles from "./styles.module.scss";
import cn from "@/libs/cn";

type typeProps = {
  children?: any;
  sticky?: boolean;
  top?: number;
  className?: string;
};

const BlockMaxWidth = (props: typeProps) => {
  const { children, sticky, top, className } = props;

  const style = useMemo(() => {
    const st = {};
    if (sticky) {
      // @ts-ignore
      st.position = "sticky";
      // @ts-ignore
      st.top = top;
    }
    return st;
  }, [sticky, top]);

  return (
    <div className={cn(styles.BlockMaxWidth, className)} style={style}>
      {children}
    </div>
  );
};

export default BlockMaxWidth;
